export const toolboxDeveloper = {
  'kind': 'category',
  'name': 'Developer',
  'categorystyle': 'developer_category',
  'contents': [
    {
      "kind": "label",
      "text": "Debugging"
    },
    {
      'kind': 'block',
      'type': 'c_developer_print'
    },        
    {
      'kind': 'block',
      'type': 'text'
    }, 
  ],
}