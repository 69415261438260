import { useState, useEffect, useContext } from "react";
import PropTypes from 'prop-types';
// context
import { robotConnContext } from "../../../contexts/ConnectContext";
// useWebsocket
import useWebSocket, { ReadyState } from "react-use-websocket";

import { ROBOT_PORT_WSS, IS_LOGGING_ON } from "../../../config";
import { Logger } from "../../../logger";

WssConnChecker.propTypes = {
  robotIP: PropTypes.string,
  onCheckConnection: PropTypes.func
};

export default function WssConnChecker({robotIP, onCheckConnection}) {

  const { setImageFileList, setAudioFileList, setRegisteredUserList, setSpaceList } = useContext(robotConnContext);
  
  const WS_URL = `wss://${robotIP}:${ROBOT_PORT_WSS}`;

  const [isUpdateImageFileListDone, setIsUpdateImageFileListDone] = useState(false);
  const [isUpdateAudioFileListDone, setIsUpdateAudioFileListDone] = useState(false);
  const [isUpdateUserListDone, setIsUpdateUserListDone] = useState(false);
  const [isUpdateSpaceListDone, setIsUpdateSpaceListDone] = useState(false);

  const { readyState, sendJsonMessage, lastJsonMessage } = useWebSocket(WS_URL, {
    onOpen: () => {
      Logger('useWebSocket onOpen');      
    },
    onClose: () => {
      Logger('useWebSocket onClose');      
    },
    onError: (e) => {
      onCheckConnection('error');
      Logger('websocket error:', e);      
    }
  })

  const connectionStatus = {
    [ReadyState.CONNECTING]: 'Connecting',
    [ReadyState.OPEN]: 'Open',
    [ReadyState.CLOSING]: 'Closing',
    [ReadyState.CLOSED]: 'Closed',
    [ReadyState.UNINSTANTIATED]: 'Uninstantiated',
  }[readyState];

  useEffect(() => {
    if(lastJsonMessage) handleMessage(lastJsonMessage);
  }, [lastJsonMessage]);

  useEffect(() => {
    if (readyState < 1) return;
    // Logger('readyState: ', connectionStatus);

    if(connectionStatus === 'Open') {
      requestUpdateFileList();
      requestUpdateUserList();
      requestUpdateSpaceList();
    }

    return () => {
      Logger('wssConnChecker unmounted.');
    }         
  }, [readyState]);

  useEffect(() => {
    if (!isUpdateImageFileListDone || !isUpdateAudioFileListDone || !isUpdateUserListDone || !isUpdateSpaceListDone) return;
    setIsUpdateImageFileListDone(false);
    setIsUpdateAudioFileListDone(false);
    setIsUpdateUserListDone(false);
    setIsUpdateSpaceListDone(false);
    onCheckConnection('open');
  }, [isUpdateImageFileListDone, isUpdateAudioFileListDone, isUpdateUserListDone, isUpdateSpaceListDone]);

  function requestUpdateFileList() {
    let msg = {
      msgId: 'request_update_file_list'
    }
    sendJsonMsgViaWss(msg);
  }

  function requestUpdateUserList() {
    let msg = {
      msgId: 'request_update_user_list'
    }
    sendJsonMsgViaWss(msg);
  }

  function requestUpdateSpaceList() {
    let msg = {
      msgId: 'request_update_space_list'
    }
    sendJsonMsgViaWss(msg);
  }

  function sendJsonMsgViaWss(msg) {    
    if(IS_LOGGING_ON) console.log('send message:', msg);
    sendJsonMessage(msg);
  }

  function handleUpdateImageFileList(msg){
    const imageList = msg?.imageList ?? [];    
    let newImageList = [];
    if(imageList.length !== 0){
      newImageList = Array.from(imageList).map((filename) => {
        const filenameSplit = filename.split('.')[0];   // 확장자 제거
        // let uiname = filenameSplit.split('_');
        // uiname = uiname.slice(1).join('_');
        // uiname = `${uiname.charAt(0).toUpperCase()}${uiname.slice(1).toLowerCase()}`
        // return [uiname, filenameSplit];
        return [filenameSplit, filename];
      })      
    } else {
      newImageList.push(['no files', 'no_files']);
    }
    if(IS_LOGGING_ON) console.log('newImageList:', newImageList);
    setImageFileList(newImageList);
    setIsUpdateImageFileListDone(true);   
  }

  function handleUpdateAudioFileList(msg){
    const audioList = msg?.audioList ?? [];    
    let newAudioList = [];
    if(audioList.length !== 0){
      newAudioList = Array.from(audioList).map((filename) => {
        const filenameSplit = filename.split('.')[0];    // 확장자 제거
        // let uiname = filenameSplit.split('_')[2];
        // uiname = `${uiname.charAt(0).toUpperCase()}${uiname.slice(1).toLowerCase()}`
        // return [uiname, filenameSplit];
        return [filenameSplit, filename];
      })      
    } else {
      newAudioList.push(['no files', 'no_files'])
    }
    if(IS_LOGGING_ON) console.log('newAudioList:', newAudioList);
    setAudioFileList(newAudioList);
    setIsUpdateAudioFileListDone(true);
  }

  function handleUpdateRegisteredUserList(msg){
    const userList = msg?.userList ?? [];    
    let newUserList = [];
    if(userList.length !== 0){
      newUserList = Array.from(userList).map((user) => {
        const username = user.name;
        const userId = user.userId
        // const uiname = `${username.charAt(0).toUpperCase()}${username.slice(1).toLowerCase()}`        
        return [username, userId.toString()];
      })      
    }
    newUserList.push(['Anyone', '0']);
    if(IS_LOGGING_ON) console.log('newUserList:', newUserList);
    setRegisteredUserList(newUserList);
    setIsUpdateUserListDone(true);
  }

  function handleUpdateSpaceList(msg){
    const spaceList = msg?.spaceList ?? [];   
    let newSpaceList = [];
    if(spaceList.length !== 0){
      newSpaceList = Array.from(spaceList).map((space) => {
        const spacename = space.name;    
        return [spacename, spaceList.indexOf(space).toString()];        
      })      
    } else {
      newSpaceList.push(['no space', 'no_space'])
    }
    if(IS_LOGGING_ON) console.log('newSpaceList', newSpaceList);
    setSpaceList(newSpaceList);
    setIsUpdateSpaceListDone(true);
  }

  function handleMessage(msg) {
    if(IS_LOGGING_ON) console.log('received message:', msg);
    let msgId;
    Object.keys(msg).includes('msgId') ? msgId = msg.msgId : msgId = 'noId';
    switch(msgId){
      case 'update_image_file_list':
        handleUpdateImageFileList(msg);
        break;
      case 'update_audio_file_list':
        handleUpdateAudioFileList(msg);
        break;
      case 'update_registered_user_list':
        handleUpdateRegisteredUserList(msg);
        break;
      case 'update_space_list':
        handleUpdateSpaceList(msg);
        break;
      default:
    }
  }

  return 
};

 