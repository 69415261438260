import { javascriptGenerator } from "blockly/javascript";

javascriptGenerator.forBlock['c_navigation_prepare'] = function(block, generator) {	
	const task = 'prepare_navi'
  const code = `startNaviTaskAsync('${task}');\nnaviTaskFinishChecker();\n`;
	return code;
};

javascriptGenerator.forBlock['c_navigation_createMap_sync'] = function(block, generator) {	
  const task = 'create_map';
  const code = `startNaviTaskAsync('${task}');\nnaviTaskFinishChecker();\n`;
	return code;
};

javascriptGenerator.forBlock['c_navigation_createMap_async'] = function(block, generator) {	  
  const task = 'create_map';
  const code = `startNaviTaskAsync('${task}');\n`;
	return code;
};

// javascriptGenerator.forBlock['c_navigation_cancelCreateMap'] = function(block, generator) {	
//   const task = 'create_map';
//   const code = `cancelNaviTask('${task}');\n`;
// 	return code;
// };

javascriptGenerator.forBlock['c_navigation_goHome_sync'] = function(block, generator) {	
  const task = 'go_home';
  const code = `startNaviTaskAsync('${task}');\nnaviTaskFinishChecker();\n`;
	return code;
};

javascriptGenerator.forBlock['c_navigation_goHome_async'] = function(block, generator) {	
  const task = 'go_home';
  const code = `startNaviTaskAsync('${task}');\n`;
	return code;
};

// javascriptGenerator.forBlock['c_navigation_cancelGoHome'] = function(block, generator) {	
//   const task = 'go_home';
//   const code = `cancelNaviTask('${task}');\n`;
// 	return code;
// };

javascriptGenerator.forBlock['c_navigation_dockToCharger_sync'] = function(block, generator) {	
  const task = 'dock_to_charger';
  const code = `startNaviTaskAsync('${task}');\nnaviTaskFinishChecker();\n`;
	return code;
};

javascriptGenerator.forBlock['c_navigation_dockToCharger_async'] = function(block, generator) {	
  const task = 'dock_to_charger';
  const code = `startNaviTaskAsync('${task}');\n`;
	return code;
};

// javascriptGenerator.forBlock['c_navigation_cancelDockToCharger'] = function(block, generator) {	
//   const task = 'dock_to_charger';
//   const code = `cancelNaviTask('${task}');\n`;
// 	return code;
// };

javascriptGenerator.forBlock['c_navigation_undockFromCharger_sync'] = function(block, generator) {	
  const task = 'undock_from_charger';
  const code = `startNaviTaskAsync('${task}');\nnaviTaskFinishChecker();\n`;
	return code;
};

javascriptGenerator.forBlock['c_navigation_undockFromCharger_async'] = function(block, generator) {	
  const task = 'undock_from_charger';
  const code = `startNaviTaskAsync('${task}');\n`;
	return code;
};

// javascriptGenerator.forBlock['c_navigation_cancelUndockFromCharger'] = function(block, generator) {	
//   const task = 'undock_from_charger';
//   const code = `cancelNaviTask('${task}');\n`;
// 	return code;
// };

javascriptGenerator.forBlock['c_navigation_exploreNodes_sync'] = function(block, generator) {	
  const task = 'explore_nodes';
  const code = `startNaviTaskAsync('${task}');\nnaviTaskFinishChecker();\n`;
	return code;
};

javascriptGenerator.forBlock['c_navigation_exploreNodes_async'] = function(block, generator) {
  const task = 'explore_nodes';	
  const code = `startNaviTaskAsync('${task}');\n`;
	return code;
};

// javascriptGenerator.forBlock['c_navigation_cancelExploreNodes'] = function(block, generator) {	
//   const task = 'explore_nodes';
//   const code = `cancelNaviTask('${task}');\n`;
// 	return code;
// };

javascriptGenerator.forBlock['c_navigation_navigateToSpace_sync'] = function(block, generator) {
  const task = 'navigate_to_space';	
	const spaceIdx = Number(block.getFieldValue('SPACELIST'));
  const code = `startNaviTaskAsync('${task}', ${spaceIdx});\nnaviTaskFinishChecker();\n`;
	return code;
};

javascriptGenerator.forBlock['c_navigation_navigateToSpace_async'] = function(block, generator) {	
  const task = 'navigate_to_space';	
  const spaceIdx = Number(block.getFieldValue('SPACELIST'));
  const code = `startNaviTaskAsync('${task}', ${spaceIdx});\n`;
	return code;
};

// javascriptGenerator.forBlock['c_navigation_cancelNavigateToSpace'] = function(block, generator) {	
//   const task = 'navigate_to_space';	
//   const code = `cancelNaviTask('${task}');\n`;
// 	return code;
// };

javascriptGenerator.forBlock['c_navigation_spinToFindPerson_sync'] = function(block, generator) {	
	const task = 'spin_to_find_person';	
  const userIdx = Number(block.getFieldValue('USERLIST'));
  const code = `startNaviTaskAsync('${task}', ${userIdx});\nnaviTaskFinishChecker();\n`;
	return code;
};

javascriptGenerator.forBlock['c_navigation_spinToFindPerson_async'] = function(block, generator) {	
	const task = 'spin_to_find_person';	
  const userIdx = Number(block.getFieldValue('USERLIST'));
  const code = `startNaviTaskAsync('${task}', ${userIdx});\n`;
	return code;
};

// javascriptGenerator.forBlock['c_navigation_cancelSpinToFindPerson'] = function(block, generator) {	
//   const task = 'spin_to_find_person';	
//   const code = `cancelNaviTask('${task}');\n`;
// 	return code;
// };

// javascriptGenerator.forBlock['c_navigation_contactEyes_sync'] = function(block, generator) {	
// 	const userIdx = Number(block.getFieldValue('USERLIST'));
//   const code = `startContactEyes(${userIdx});\nnaviTaskFinishChecker();\n`;
// 	return code;
// };

javascriptGenerator.forBlock['c_navigation_contactEyes_async'] = function(block, generator) {	
	const task = 'contact_eyes';	
  const userIdx = Number(block.getFieldValue('USERLIST'));
  const code = `startNaviTaskAsync('${task}', ${userIdx});\n`;
	return code;
};

// javascriptGenerator.forBlock['c_navigation_cancelContactEyes'] = function(block, generator) {	
//   const task = 'contact_eyes';	
//   const code = `cancelNaviTask('${task}');\n`;
// 	return code;
// };

javascriptGenerator.forBlock['c_navigation_comeHere_sync'] = function(block, generator) {	
	const task = 'come_here';	
  const userIdx = Number(block.getFieldValue('USERLIST'));
  const code = `startNaviTaskAsync('${task}', ${userIdx});\nnaviTaskFinishChecker();\n`;
	return code;
};

javascriptGenerator.forBlock['c_navigation_comeHere_async'] = function(block, generator) {	
	const task = 'come_here';	
  const userIdx = Number(block.getFieldValue('USERLIST'));
  const code = `startNaviTaskAsync('${task}', ${userIdx});\n`;
	return code;
};

// javascriptGenerator.forBlock['c_navigation_cancelComeHere'] = function(block, generator) {	
//   const task = 'come_here';	
//   const code = `cancelNaviTask('${task}');\n`;
// 	return code;
// };

// javascriptGenerator.forBlock['c_navigation_followMe_sync'] = function(block, generator) {	
// 	const userIdx = Number(block.getFieldValue('USERLIST'));
//   const code = `startFollowMe(${userIdx});\nnaviTaskFinishChecker();\n`;
// 	return code;
// };

javascriptGenerator.forBlock['c_navigation_followMe_async'] = function(block, generator) {	
	const task = 'follow_me';	
  const userIdx = Number(block.getFieldValue('USERLIST'));
  const code = `startNaviTaskAsync('${task}', ${userIdx});\n`;
	return code;
};

// javascriptGenerator.forBlock['c_navigation_cancelFollowMe'] = function(block, generator) {	
//   const task = 'follow_me';	
//   const code = `cancelNaviTask('${task}');\n`;
// 	return code;
// };

javascriptGenerator.forBlock['c_navigation_cancelNaviTask'] = function(block, generator) {	  
  const code = `cancelNaviTaskAsync();\n`;
	return code;
};


