import Blockly from 'blockly/core';
import '@blockly/field-slider';
import { facialExpressionList } from './faceList';
import { playEffectSoundList, playEmotionSoundList } from './soundList';

// block type naming rule : {toolbox_name}_{block_description}

const facialExpressionJson = {
	"message0": "play face animation %1 %2",
	"args0": [
		{
			'type': 'field_dropdown',
			'name': 'FACE',
			'options': [],
		},
		{
			'type': 'field_dropdown',
			'name': 'REPEAT',
			'options': [
				["once", "0"],
				["repeatedly", "1"],
			],
		}
	],
	"previousStatement": null,
	"nextStatement": null,
	"style": "interaction_blocks",
}
const facialExpressionOptions = Array.from(facialExpressionList).map((face) => {
	let index = facialExpressionList.indexOf(face) + 9;
	return [ face, index.toString()]
});
facialExpressionJson.args0[0].options = facialExpressionOptions;
Blockly.Blocks['c_interaction_facialExpression'] = {
	init: function() {
		this.jsonInit(facialExpressionJson);
	}
}

const returnToNormalFaceJson = {
	"message0": "return to normal face animation",
	"previousStatement": null,
	"nextStatement": null,
	"style": "interaction_blocks",
}
Blockly.Blocks['c_interaction_returnToNormalFace'] = {
	init: function() {
		this.jsonInit(returnToNormalFaceJson);
	}
}

const displayTextJson = {
	// "implicitAlign0": "CENTRE",
	"message0": 'display text %1',
	"args0": [
		{
			"type": "input_value",
			"name": "TEXT",
		}
	],
	"previousStatement": null,
	"nextStatement": null,
	"style": "interaction_blocks",
}
Blockly.Blocks['c_interaction_displayText'] = {
	init: function() {
		this.jsonInit(displayTextJson);
	},

	onchange: function(e) {		
		if (this.workspace.isDragging()) return;
		if (e.type !== Blockly.Events.BLOCK_CHANGE) return;
		let textVal = this.getChildren()[0].getFieldValue('TEXT');
		if (textVal === '') {			
			this.unplug();
			this.getChildren()[0].setFieldValue('텍스트를 입력해주세요', 'TEXT');
		}
	}
}

const displayOnOffJson = {
	"message0": "turn %1 display",
	"args0": [
		{
			'type': 'field_dropdown',
			'name': 'ONOFF',
			'options': [
				["ON", '1'],
				["OFF", '0'],
			],
		}
	],
	"previousStatement": null,
	"nextStatement": null,
	"style": "interaction_blocks",
}
Blockly.Blocks['c_interaction_displayOnOff'] = {
	init: function() {
		this.jsonInit(displayOnOffJson);
	}
}

const setBrightnessJson = {
	"message0": "set brightness to %1",
	"args0": [
		{
			"type": "field_slider",
			"name": "LEVEL",
			"min": 0,
			"max": 10,
			"value": 6,
			"precision": 1
		},
	],
	"previousStatement": null,
	"nextStatement": null,
	"style": "interaction_blocks",
}
Blockly.Blocks['c_interaction_setBrightness'] = {
	init: function() {
		this.jsonInit(setBrightnessJson);
	}
}

const updateToastMessageJson = {
	// "implicitAlign0": "CENTRE",
	"message0": 'display toast message %1',
	"args0": [
		{
			"type": "input_value",
			"name": "TEXT",
		}
	],
	"previousStatement": null,
	"nextStatement": null,
	"style": "interaction_blocks",
}
Blockly.Blocks['c_interaction_updateToastMessage'] = {
	init: function() {
		this.jsonInit(updateToastMessageJson);
	},

	onchange: function(e) {		
		if (this.workspace.isDragging()) return;
		if (e.type !== Blockly.Events.BLOCK_CHANGE) return;
		let textVal = this.getChildren()[0].getFieldValue('TEXT');
		if (textVal === '') {			
			this.unplug();
			this.getChildren()[0].setFieldValue('텍스트를 입력해주세요', 'TEXT');
		}
	}
}

const dismissToastMessageJson = {
	"message0": "remove all toast messages",
	"inputsInline": false,
	"previousStatement": null,
	"nextStatement": null,
	"style": "interaction_blocks",
}
Blockly.Blocks['c_interaction_dismissToastMessage'] = {
	init: function() {
		this.jsonInit(dismissToastMessageJson);
	}
}

const playEmotionSoundJson = {
	"message0": "",
	"args0": [
		{
			'type': 'field_dropdown',
			'name': 'SOUND',
			'options': [],
		}
	],
	"previousStatement": null,
	"nextStatement": null,
	"style": "interaction_blocks",
}

const playEmotionSoundOptions = Array.from(playEmotionSoundList).map((sound) => {
	return [ sound, playEmotionSoundList.indexOf(sound).toString()]
});

const playEmotionSoundSyncJson = JSON.parse(JSON.stringify(playEmotionSoundJson));
playEmotionSoundSyncJson.message0 = "play emotion sound %1 until end";
playEmotionSoundSyncJson.args0[0].options = playEmotionSoundOptions;

Blockly.Blocks['c_interaction_playEmotionSound_sync'] = {
	init: function() {
		this.jsonInit(playEmotionSoundSyncJson);
	}
}

const playEmotionSoundAsyncJson = JSON.parse(JSON.stringify(playEmotionSoundJson));
playEmotionSoundAsyncJson.message0 = "play emotion sound %1";
playEmotionSoundAsyncJson.args0[0].options = playEmotionSoundOptions;

Blockly.Blocks['c_interaction_playEmotionSound_async'] = {
	init: function() {
		this.jsonInit(playEmotionSoundAsyncJson);
	}
}

const playEffectSoundJson = {
	"message0": "",
	"args0": [
		{
			'type': 'field_dropdown',
			'name': 'SOUND',
			'options': [],
		}
	],
	"previousStatement": null,
	"nextStatement": null,
	"style": "interaction_blocks",
}

const playEffectSoundOptions = Array.from(playEffectSoundList).map((sound) => {
	let index = playEffectSoundList.indexOf(sound) + 2;
	return [ sound, index.toString()]
});

const playEffectSoundSyncJson = JSON.parse(JSON.stringify(playEffectSoundJson));
playEffectSoundSyncJson.message0 = "play effect sound %1 until end";
playEffectSoundSyncJson.args0[0].options = playEffectSoundOptions;

Blockly.Blocks['c_interaction_playEffectSound_sync'] = {
	init: function() {
		this.jsonInit(playEffectSoundSyncJson);
	}
}

const playEffectSoundAsyncJson = JSON.parse(JSON.stringify(playEffectSoundJson));
playEffectSoundAsyncJson.message0 = "play effect sound %1";
playEffectSoundAsyncJson.args0[0].options = playEffectSoundOptions;

Blockly.Blocks['c_interaction_playEffectSound_async'] = {
	init: function() {
		this.jsonInit(playEffectSoundAsyncJson);
	}
}

const playTtsJson = {
	"implicitAlign0": "CENTRE",
	"message0": '',
	"args0": [
		{
			"type": "input_value",
			"name": "TEXT",
		}
	],
	"previousStatement": null,
	"nextStatement": null,
	"style": "interaction_blocks",
}

const playTtsSyncJson = JSON.parse(JSON.stringify(playTtsJson));
playTtsSyncJson.message0 = "play text %1 to speech until end";

Blockly.Blocks['c_interaction_playTts_sync'] = {
	init: function() {
		this.jsonInit(playTtsSyncJson);
	},

	onchange: function(e) {		
		if (this.workspace.isDragging()) return;
		if (e.type !== Blockly.Events.BLOCK_CHANGE) return;
		let textVal = this.getChildren()[0].getFieldValue('TEXT');
		if (textVal === '') {			
			this.unplug();
			this.getChildren()[0].setFieldValue('텍스트를 입력해주세요', 'TEXT');
		}
	}
}

const playTtsAsyncJson = JSON.parse(JSON.stringify(playTtsJson));
playTtsAsyncJson.message0 = "play text %1 to speech";

Blockly.Blocks['c_interaction_playTts_async'] = {
	init: function() {
		this.jsonInit(playTtsAsyncJson);
	},

	onchange: function(e) {		
		if (this.workspace.isDragging()) return;
		if (e.type !== Blockly.Events.BLOCK_CHANGE) return;
		let textVal = this.getChildren()[0].getFieldValue('TEXT');
		if (textVal === '') {			
			this.unplug();
			this.getChildren()[0].setFieldValue('텍스트를 입력해주세요', 'TEXT');
		}
	}
}

const stopSoundJson = {
	"message0": "stop all robot sound",
	"inputsInline": false,
	"previousStatement": null,
	"nextStatement": null,
	"style": "interaction_blocks",
}

Blockly.Blocks['c_interaction_stopSound'] = {
	init: function() {
		this.jsonInit(stopSoundJson);
	}
}

const textFromSpeechJson = {
	"message0": "text from speech",
	"output": "String",
	"style": "interaction_blocks",
	"tooltip": "This block enables the speech to text feature and returns the text for the input speech."
}

Blockly.Blocks['c_interaction_textFromSpeech'] = {
	init: function() {
		this.jsonInit(textFromSpeechJson);
	}
}

// const startVoiceRecordingJson = {
// 	"message0": "start voice recording",
// 	"inputsInline": false,
// 	"previousStatement": null,
// 	"nextStatement": null,
// 	"style": "interaction_blocks",
// }

// Blockly.Blocks['c_interaction_startVoiceRecording'] = {
// 	init: function() {
// 		this.jsonInit(startVoiceRecordingJson);
// 	}
// }

const volumeControlJson = {
	"message0": "set volume to %1",
	"args0": [
		{
			"type": "field_slider",
			"name": "VOLUME",
			"min": 1,
			"max": 10,
			"value": 6,
			"precision": 1
		},
	],
	"previousStatement": null,
	"nextStatement": null,
	"style": "interaction_blocks",
}

Blockly.Blocks['c_interaction_volumeControl'] = {
	init: function() {
		this.jsonInit(volumeControlJson);
	}
}

// Extensions and Mutators