export const motionList = [
  "E1_AWE_1",      // 0
	// "E1_AWE_2",
	"E2_EXCITING_1",
	"E2_EXCITING_2",
	"E3_SURPRISE_1",
	"E4_ANTICIPATION_1",
	"E4_ANTICIPATION_2",
	"E4_ANTICIPATION_3",
	"E5_CONFIDENT_1",
	"E5_CONFIDENT_2",
	"E6_LOVE_1",     
	"E7_DELIGHT_1",    // 10
	"E7_DELIGHT_2",
	"E8_JOY_1",
	"E8_JOY_2",
	"E9_CURIOSITY_1",
	"E12_RELIEVED_1",
	"E13_SATISFIED_1",
	"E14_TOUCHED_A_1",
	"E14_TOUCHED_B_1",
	"E15_AGREE_1",
	"E16_SHYNESS_A_1",   // 20
	"E16_SHYNESS_B_1",
	"E17_PROUD_1",
	"E18_INTERESTED_1",
	"E19_PLAYFUL_1",
	"E20_TREMBLE_1",
	"E21_THRILLED_1",
	"E22_THINKING_A_1",
	"E22_THINKING_B_1",
	"E51_DIZZY_1",
	"E52_SADNESS_1",     // 30
	"E53_SHAME_1",
	"E54_ANXIETY_1",
	"E54_ANXIETY_2",
	"E55_FEAR_1",
	"E56_ASHAMED_1",
	"E57_SHOCKED_1",
	"E58_BORED_1",
	"E59_TIRED_1",
	"E60_CONFUSED_1",
	"E61_EMBARRASSED_1",    // 40
	"E62_JEALOUS_1",
	"E63_SUSPICIOUS_1",
	"E64_MAD_1",
	"E65_UNSATISFIED_1",
	"E66_ANNOYED_1",
	"F0_CONVERSATION_ANALYZING_1",
  "F0_CONVERSATION_ANALYZING_2",
  "F0_CONVERSATION_ANALYZING_3",
	"F0_CONVERSATION_FAILED_1",
	"D1_LOOK_AROUND_1",     // 50
	"A1_SHAKE_HEADSET_1",    // 51
	"A2_NOTICE_1",
	"A3_VOICE_MAIL_DELIVERY_END",
	"A4_AWAKE_WORD_1",
  "UP_DOWN",
  "DOWN_UP",
  // "E12_DO_NOT_KNOW_1",
	// "E12_DO_NOT_KNOW_2",
	// "E13_EXCLAMATION_1",
	// "E14_DIZZY_1",
	// "E15_SADNESS_1",   
	// "E16_SHAME_1",
	// "E17_ANXIETY_1",
	// "E17_ANXIETY_2",
	// "E18_FEAR_2",
	// "T1_S_MOVE_6S"  // 700,
  // "DANCE_1"      // 900,
	// "DANCE_FUNKY",
	// "DANCE_SWING",
	// "DANCE_EDM",
	// "DANCE_EDM_FUNKY_CES_LEFT",
	// "DANCE_EDM_FUNKY_CES_CENTER",
	// "DANCE_EDM_FUNKY_CES_RIGHT",
	// "KBIS_DANCE_1",
	// "KBIS_DANCE_2",
	// "KBIS_DANCE_3",
	// "KBIS_DANCE_4",
	// "KBIS_DANCE_5",
]