import { javascriptGenerator } from "blockly/javascript";
import { Order } from 'blockly/javascript';

javascriptGenerator.forBlock['c_interaction_facialExpression'] = function(block, generator) {
	const facialExpression = Number(block.getFieldValue('FACE'));	
	const repeat = Number(block.getFieldValue('REPEAT'));	
	// const code = `playFacialExpression(${facialExpression}, ${repeat});\n`;
	const code = `playFacialExpressionAsync(${facialExpression}, ${repeat});\n`;
	return code;
};

javascriptGenerator.forBlock['c_interaction_returnToNormalFace'] = function(block, generator) {
	const facialExpression = 85;	
	const repeat = 0;
	const code = `playFacialExpressionAsync(${facialExpression}, ${repeat});\n`;
	return code;
};

javascriptGenerator.forBlock['c_interaction_displayImage'] = function(block, generator) {
	const image = block.getFieldValue('IMAGE');	
	const code = `displayImage('${image}');\n`;
	return code;
};

javascriptGenerator.forBlock['c_interaction_displayText'] = function(block, generator) {
	const text = generator.valueToCode(block, 'TEXT', Order.NONE) || "''";	
	const code = `displayText(${text});\n`;
	return code;
}; 

javascriptGenerator.forBlock['c_interaction_displayOnOff'] = function(block, generator) {
	const onOff = Number(block.getFieldValue('ONOFF'));	
	const code = `displayOnOff(${onOff});\n`;
	return code;
}; 

javascriptGenerator.forBlock['c_interaction_setBrightness'] = function(block, generator) {	
	const level = block.getFieldValue('LEVEL');	
	const code = `setBrightness(${level});\n`;
	return code;
};

javascriptGenerator.forBlock['c_interaction_updateToastMessage'] = function(block, generator) {
	const text = generator.valueToCode(block, 'TEXT', Order.NONE) || "''";	
	const code = `updateToastMessage(${text});\n`;
	return code;
}; 

javascriptGenerator.forBlock['c_interaction_dismissToastMessage'] = function(block, generator) {
	const code = `dismissToastMessage();\n`;
	return code;
}; 

javascriptGenerator.forBlock['c_interaction_playEmotionSound_sync'] = function(block, generator) {
	const sound = Number(block.getFieldValue('SOUND'));	
	// const code = `playEmotionSound(${sound});\nsoundFinishChecker();\n`;
	const code = `playEmotionSoundAsync(${sound});\nsoundFinishChecker();\n`;
	return code;
};

javascriptGenerator.forBlock['c_interaction_playEmotionSound_async'] = function(block, generator) {
	const sound = Number(block.getFieldValue('SOUND'));
	// const code = `playEmotionSound(${sound});\n`;
	const code = `playEmotionSoundAsync(${sound});\n`;
	return code;
}

javascriptGenerator.forBlock['c_interaction_playEffectSound_sync'] = function(block, generator) {
	const sound = Number(block.getFieldValue('SOUND'));	
	const code = `playEffectSoundAsync(${sound});\nsoundFinishChecker();\n`;
	return code;
};

javascriptGenerator.forBlock['c_interaction_playEffectSound_async'] = function(block, generator) {
	const sound = Number(block.getFieldValue('SOUND'));
	// const code = `playEmotionSound(${sound});\n`;
	const code = `playEffectSoundAsync(${sound});\n`;
	return code;
}

javascriptGenerator.forBlock['c_interaction_playSound_sync'] = function(block, generator) {
	const sound = block.getFieldValue('SOUND');	
	// const code = `playSound('${sound}');\nsoundFinishChecker();\n`;
	const code = `playSoundAsync('${sound}');\nsoundFinishChecker();\n`;
	return code;
};

javascriptGenerator.forBlock['c_interaction_playSound_async'] = function(block, generator) {
	const sound = block.getFieldValue('SOUND');	
	// const code = `playSound('${sound}');\n`;
	const code = `playSoundAsync('${sound}');\n`;
	return code;
}

javascriptGenerator.forBlock['c_interaction_playTts_sync'] = function(block, generator) {
	const text = generator.valueToCode(block, 'TEXT', Order.NONE) || "''";	
	// const code = `playTTS(${text});\nsoundFinishChecker();\n`;
	const code = `playTTSAsync(${text});\nsoundFinishChecker();\n`;
	return code;
}; 

javascriptGenerator.forBlock['c_interaction_playTts_async'] = function(block, generator) {
	const text = generator.valueToCode(block, 'TEXT', Order.NONE) || "''";	
	// const code = `playTTS(${text});\n`;
	const code = `playTTSAsync(${text});\n`;
	return code;
}; 

javascriptGenerator.forBlock['c_interaction_stopSound'] = function(block, generator) {
	const code = `stopSoundAsync();\n`;
	return code;
}; 

javascriptGenerator.forBlock['c_interaction_textFromSpeech'] = function(block, generator) {
	const withStepUI = block.workspace.getBlocksByType('c_program_start', false)[0].getFieldValue('STEP') === 'TRUE';
	let code;
	withStepUI ? 
	code = `getTextFromSpeech(highlightBlock('${block.id}', false), startSTT(),sttFinishChecker())`
	: code = `getTextFromSpeech(startSTT(),sttFinishChecker())`;
  return [code, Order.NONE];
};

// javascriptGenerator.forBlock['c_interaction_startVoiceRecording'] = function(block, generator) {	
// 	const code = `startVoiceRecording();\nvoiceRecordingFinishChecker();\n`;
// 	return code;
// }; 

javascriptGenerator.forBlock['c_interaction_volumeControl'] = function(block, generator) {	
	const volume = block.getFieldValue('VOLUME');	
	const code = `volumeControl(${volume});\n`;
	return code;
};