import { createContext, useState } from "react";

export const robotConnContext = createContext(null);

export const ConnectContext = (props) => {
    const [isReadyContext, setIsReadyContext] = useState(false);
    const [robotIPContext, setRobotIPContext] = useState('');
    const [imageFileList, setImageFileList] = useState([]);
    const [audioFileList, setAudioFileList] = useState([]);
    const [registeredUserList, setRegisteredUserList] = useState([]);
    const [spaceList, setSpaceList] = useState([]);

    return (
        <robotConnContext.Provider 
            value={{ 
                isReadyContext, setIsReadyContext, 
                robotIPContext, setRobotIPContext,
                imageFileList, setImageFileList,
                audioFileList, setAudioFileList,
                registeredUserList, setRegisteredUserList,
                spaceList, setSpaceList
            }}
        >
            {props.children}
        </robotConnContext.Provider>
    )
}