export const toolboxMath = {
  'kind': 'category',
  'name': 'Math',
  'categorystyle': 'math_category',
  'contents': [        
    {
      'kind': 'block',
      'type': 'math_arithmetic',
      "inputs": {
        "A": {
          "shadow": {
            "type": "math_number",
            "fields": {
              "NUM": 1
            }
          }
        },
        "B": {
          "shadow": {
            "type": "math_number",
            "fields": {
              "NUM": 1
            }
          }
        }
      }
    },
    {
      'kind': 'block',
      'type': 'math_modulo',
      "inputs": {
        "DIVIDEND": {
          "shadow": {
            "type": "math_number",
            "fields": {
              "NUM": 64
            }
          }
        },
        "DIVISOR": {
          "shadow": {
            "type": "math_number",
            "fields": {
              "NUM": 10
            }
          }
        }
      }
    },
    {
      'kind': 'block',
      'type': 'math_random_int',
      "inputs": {
        "FROM": {
          "shadow": {
            "type": "math_number",
            "fields": {
              "NUM": 1
            }
          }
        },
        "TO": {
          "shadow": {
            "type": "math_number",
            "fields": {
              "NUM": 100
            }
          }
        }
      }
    },
    {
      'kind': 'block',
      'type': 'math_number',
      'fields':{
        'NUM': 123
      }
    },
  ],
}