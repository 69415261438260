export const toolboxLogic = {
  'kind': 'category',
  'name': 'Logic',
  'categorystyle': 'logic_category',
  'contents': [
    {
      'kind': 'block',
      'type': 'controls_if'
    },
    {
      'kind': 'block',
      'type': 'logic_compare',
    },
    {
      'kind': 'block',
      'type': 'logic_operation',
    },
    {
      'kind': 'block',
      'type': 'logic_negate',
    },
    {
      'kind': 'block',
      'type': 'c_logic_wait_until',
    },
    {
      'kind': 'block',
      'type': 'c_logic_wait_seconds',
    }
  ],
}