export const toolboxInteraction = {
  'kind': 'category',
  'name': 'Interaction',
  'categorystyle': 'interaction_category',
  'contents': [            
    {
      "kind": "label",
      "text": "DISPLAY"
    },
    {
      'kind': 'block',
      'type': 'c_interaction_facialExpression'
    },
    {
      'kind': 'block',
      'type': 'c_interaction_returnToNormalFace'
    },
    {
      'kind': 'block',
      'type': 'c_interaction_displayImage'
    },
    {
      'kind': 'block',
      'type': 'c_interaction_displayText',
      'inputs': {
        'TEXT': {
          'shadow': {
            'type': 'text',
            'fields': {
              "TEXT": "Hello"
            }
          }
        }
      }
    },
    {
      'kind': 'block',
      'type': 'c_interaction_displayOnOff'
    },
    {
      'kind': 'block',
      'type': 'c_interaction_setBrightness'
    },   
    {
      'kind': 'block',
      'type': 'c_interaction_updateToastMessage',
      'inputs': {
        'TEXT': {
          'shadow': {
            'type': 'text',
            'fields': {
              "TEXT": "Good morning"
            }
          }
        }
      }
    },   
    {
      'kind': 'block',
      'type': 'c_interaction_dismissToastMessage'
    },   
    {
      "kind": "label",
      "text": "SOUND"
    },
    // {
    //   'kind': 'block',
    //   'type': 'c_interaction_playEmotionSound_sync'
    // },
    // {
    //   'kind': 'block',
    //   'type': 'c_interaction_playEmotionSound_async'
    // },      
    {
      'kind': 'block',
      'type': 'c_interaction_playEffectSound_sync'
    },
    {
      'kind': 'block',
      'type': 'c_interaction_playEffectSound_async'
    },      
    {
      'kind': 'block',
      'type': 'c_interaction_playSound_sync'
    },      
    {
      'kind': 'block',
      'type': 'c_interaction_playSound_async'
    },      
    {
      'kind': 'block',
      'type': 'c_interaction_playTts_sync',
      'inputs': {
        'TEXT': {
          'shadow': {
            'type': 'text',
            'fields': {
              "TEXT": "Hello"
            }
          }
        }
      }
    },      
    {
      'kind': 'block',
      'type': 'c_interaction_playTts_async',
      'inputs': {
        'TEXT': {
          'shadow': {
            'type': 'text',
            'fields': {
              "TEXT": "Hello"
            }
          }
        }
      }
    },  
    {
      'kind': 'block',
      'type': 'c_interaction_stopSound'
    },     
    {
      'kind': 'block',
      'type': 'c_interaction_textFromSpeech'
    },     
    // {
    //   'kind': 'block',
    //   'type': 'c_interaction_startVoiceRecording'
    // },     
    {
      'kind': 'block',
      'type': 'c_interaction_volumeControl'
    },     
  ],
}