export const toolboxLoop = {
  'kind': 'category',
  'name': 'Loop',
  'categorystyle': 'loop_category',
  'contents': [
    {
      'kind': 'block',
      'type': 'controls_repeat_ext',
      "inputs": {
        "TIMES": {
          "shadow": {
            "type": "math_number",
            "fields": {
              "NUM": 10
            }
          }
        }
      }
    },         
    {
      'kind': 'block',
      'type': 'c_loop_repeat_while',
    },    
    {
      'kind': 'block',
      'type': 'c_loop_repeat_until',
    },         
    {
      'kind': 'block',
      'type': 'c_loop_repeat_infinitely',
    },
    {
      'kind': 'block',
      'type': 'c_loop_continue',
    },
    {
      'kind': 'block',
      'type': 'c_loop_break',
    },
  ],
}