import { Outlet } from 'react-router-dom';
// @mui
import { Stack } from '@mui/material';
// components
import MainHeader from './MainHeader';

// ----------------------------------------------------------------------

export default function WorkspaceLayout() {

  return (
      <Stack sx={{ minHeight: 1 }}>    
      { /* <MainHeader /> */ }

      <Outlet />

      </Stack>
  );
}
