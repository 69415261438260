import { toolboxInteraction } from "./interaction";
import { toolboxMotion } from "./motion";
import { toolboxMovement } from "./movement";
import { toolboxSensing } from "./sensing";
import { toolboxNavigation } from "./navigation";
import { toolboxLogic } from "./logic";
import { toolboxMath } from "./math";
import { toolboxLoop } from "./loop";
import { toolboxVariable } from "./variable";
import { toolboxDeveloper } from "./developer";


const toolbox = {
  'kind': 'categoryToolbox',
  'contents': [
    toolboxInteraction,
		toolboxMotion,
		toolboxMovement,
		toolboxSensing,
		toolboxNavigation,
		toolboxLogic,
		toolboxLoop,
		toolboxMath,		
		toolboxVariable,
		toolboxDeveloper
  ],
};

export default toolbox;