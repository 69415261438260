/* eslint-disable no-unused-vars */
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  sx: PropTypes.object,
};

export default function Logo({ sx }) {

  const logoSize = 70;

  const logo = (
    <Box sx={{ width: logoSize, height: logoSize, ...sx }}>
      <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
        width="100%" height="100%" viewBox="0 0 176.132 77.239" enableBackground="new 0 0 176.132 77.239">
        <g>
          <path fill="#A50034" d="M38.65,77.239c21.328,0,38.65-17.271,38.65-38.611C77.3,17.287,59.978,0,38.65,0
		C17.289,0,0,17.287,0,38.628C0,59.968,17.289,77.239,38.65,77.239"/>
          <polygon fill="#FFFFFF" points="37.095,21.676 37.095,55.563 47.868,55.563 47.868,52.516 40.2,52.516 40.2,21.676 	" />
          <path fill="#FFFFFF" d="M26.308,30.922c2.563,0,4.629-2.084,4.629-4.629c0-2.547-2.066-4.639-4.629-4.639s-4.629,2.092-4.629,4.639
		S23.745,30.922,26.308,30.922"/>
          <path fill="#FFFFFF" d="M40.199,6.304c-0.404-0.02-1.172-0.045-1.539-0.045c-17.84,0-32.359,14.524-32.359,32.353
		c0,8.66,3.365,16.777,9.46,22.881c6.12,6.09,14.241,9.455,22.899,9.455c8.625,0,16.744-3.365,22.853-9.455
		c6.11-6.104,9.491-14.221,9.491-22.881v-1.439h-1.309H47.898v2.978h20.024c0,0.065,0,0.381-0.014,0.463
		C66.89,55.856,54.16,67.928,38.66,67.928c-7.846,0-15.203-3.051-20.74-8.592c-5.555-5.518-8.616-12.869-8.616-20.725
		c0-7.811,3.061-15.2,8.616-20.735c5.537-5.53,12.894-8.581,20.74-8.581c0.338,0,1.16,0,1.539,0.02V6.304z"/>
          <path fill="#6B6B6B" d="M106.367,13.002H94.753V63.28h35.672v-9.787h-24.058V13.002z M155.974,44.12h9.459v8.791
		c-1.744,0.664-5.145,1.326-8.381,1.326c-10.453,0-13.935-5.309-13.935-16.092c0-10.283,3.316-16.346,13.769-16.346
		c5.809,0,9.127,1.826,11.861,5.311l7.221-6.639c-4.398-6.3-12.115-8.208-19.33-8.208c-16.264,0-24.807,8.876-24.807,25.798
		c0,16.84,7.715,25.965,24.725,25.965c7.797,0,15.43-1.99,19.576-4.893V34.743h-20.158V44.12z"/>
        </g>
      </svg>
    </Box>
  );

  return <RouterLink to="/">{logo}</RouterLink>;
}
