export const constants = {
  // headset
  HEADSET_ANGLE_NEUTRAL_POSITION : 180,   // 180 deg
  HEADSET_DEFAULT_TIME_ALLOWANCE : 1,      // 1 second
  HEADSET_DPS_SPEED_SLOW : 30,      // 30dps
  HEADSET_DPS_SPEED_NORMAL : 60,      // 60dps
  HEADSET_DPS_SPEED_FAST : 90,      // 90dps
  HEADSET_DPS_SPEED_FASTEST : 112,      // 112dps
  HEADSET_PROFILE_SPEED_SLOW : 1,      // profile speed 1
  HEADSET_PROFILE_SPEED_NORMAL : 3,      // profile speed 3
  HEADSET_PROFILE_SPEED_FAST : 6,      // profile speed 6
  HEADSET_PROFILE_SPEED_FASTEST : 8,      // profile speed 8

  // hip
  HIP_ANGLE_NEUTRAL_POSITION: 10,            // 10 deg
  HIP_DPS_SPEED_SLOW : 13,        // 13dps  
  HIP_DPS_SPEED_NORMAL : 40,      // 40dps
  HIP_DPS_SPEED_FAST : 62,        // 62dps
  HIP_PROFILE_SPEED_SLOW : 1,        // profile speed 1
  HIP_PROFILE_SPEED_NORMAL : 3,      // profile speed 3
  HIP_PROFILE_SPEED_FAST : 5,        // profile speed 5
  BODY_SPEED_NORMAL : 1,      // body 각도 1도 당 hip 각도 약 5~10도 정도 변화. Hip speed 5~10dps
  // HIP_ANGLE_MAX : 22,  

  // wheel
  WHEEL_LINEAR_SPEED_SLOW : 0.1,      // 0.1 m/s
  WHEEL_LINEAR_SPEED_NORMAL : 0.3,    // 0.3 m/s
  WHEEL_LINEAR_SPEED_FAST : 0.5,      // 0.5 m/s
  WHEEL_LINEAR_SPEED_FASTEST : 1.0,   // 1.0 m/s
  WHEEL_ANGULAR_SPEED_SLOW_RAD : 0.3,  // 0.1 rad/s -> 5.73 dps
  WHEEL_ANGULAR_SPEED_NORMAL_RAD : 0.8,   // 0.17 rad/s -> 9.74 dps
  WHEEL_ANGULAR_SPEED_FAST_RAD : 1.2,   // 0.26 rad/s -> 14.90 dps
  WHEEL_ANGULAR_SPEED_FASTEST_RAD : 1.57,   // 0.34 rad/s -> 19.48 dps, max speed -1.57 radian/s ~ 1.57 radian/s

  // etc
  HZ_MOTOR_INFO : 125,      // hertz of motor info topic
  INFINITE_ANGLE : -1,
  INFINITE_DISTANCE : -1
}