import { useEffect, useContext, forwardRef, useImperativeHandle } from "react";
import useWebSocket, { ReadyState } from "react-use-websocket";
import { constants } from "./constants";
import { ROBOT_PORT_WSS, IS_LOGGING_ON } from "../../../config";
import { Logger } from "../../../logger";
// context
import { robotConnContext } from "../../../contexts/ConnectContext";

const WsConnector = forwardRef(({ 
  ip, 
  onCloseWssConnection,
  onSoundFinished, 
  onSttFinished,
  // onVoiceRecordingFinished,
  onMotionFinished,
  onHeadsetMotionFinished,
  onHipMotionFinished,
  onWheelMoveFinished,  
  onUpdateWakeWordDetectionStatus,
  onUpdatePersonDetectionStatus, 
  onUpdateFaceDetectionStatus,
  // onUpdateFalldownStatus, 
  // onUpdatePickupStatus,  
  onUpdateAngleOfHeadset,
  onUpdateAngleOfHip, 
  onUpdateSpeedOfWheel,  
  onUpdateBatteryLevel,
  onUpdateNaviStatus,
  onUpdateCreateMapStatus,
  onUpdateIsAtHomeStatus,
  onUpdateDockToChargerStatus,
  onUpdateUndockFromChargerStatus,
  onUpdateExploreNodesStatus,
  onUpdateNavigateToSpaceStatus,
  onUpdateSpinToFindPersonStatus,
  onUpdateEyeContactStatus,
  onUpdateComeHereStatus,
  onUpdateFollowMeStatus,
  onUpdateNaviTaskCanceled,
  onUpdateSendFileResult
}, ref) => {

  const { setImageFileList, setAudioFileList } = useContext(robotConnContext);
  
  const WS_URL = `wss://${ip}:${ROBOT_PORT_WSS}`;

  const { sendJsonMessage, lastJsonMessage, readyState, } = useWebSocket(WS_URL, {
    // queryParams: {name},
    // share: true,
    // shouldReconnect: () => false,
    onOpen: () => Logger('websocket session opened'),
    onClose: () => {      
      Logger('websocket session closed');      
      onCloseWssConnection();
    },
    onError: (e) => Logger('websocket error:', e)
  })

  const connectionStatus = {
    [ReadyState.CONNECTING]: 'Connecting',
    [ReadyState.OPEN]: 'Open',
    [ReadyState.CLOSING]: 'Closing',
    [ReadyState.CLOSED]: 'Closed',
    [ReadyState.UNINSTANTIATED]: 'Uninstantiated',
  }[readyState];

  useEffect(() => {
    Logger(`readyState: ${connectionStatus}`);
  }, [readyState]);

  useImperativeHandle(ref, () => {
    return {  
      startCode(){
        startCode();
      },
      // interaction blocks
      playFacialExpression(facialExpression, repeat){
        playFacialExpression(facialExpression, repeat)
      },
      displayImage(image){
        displayImage(image);
      },
      displayText(text){
        displayText(text);
      },
      displayOnOff(onOff){
        displayOnOff(onOff);
      },
      setBrightness(level){
        setBrightness(level);
      },
      updateToastMessage(text){
        updateToastMessage(text);
      },
      dismissToastMessage(){
        dismissToastMessage();
      },
      playEmotionSound(sound){
        playEmotionSound(sound);
      },    
      playEffectSound(sound){
        playEffectSound(sound);
      },    
      playSound(sound){
        playSound(sound);
      },    
      playTTS(text){
        playTTS(text);
      },        
      stopSound(type){
        stopSound(type);
      },
      startSTT(){
        startSTT();
      },
      // startVoiceRecording(){
      //   startVoiceRecording();
      // },
      volumeControl(vol){
        volumeControl(vol);
      },
      // motion blocks
      startMotion(motionId){
        startMotion(motionId);
      },
      startInPlaceMotion(motionId, repeat){
        startInPlaceMotion(motionId, repeat);
      },
      stopMotion(){
        stopMotion();
      },          
      // movement blocks
      rotateHeadset(targetAngle, selectedSpeed){
        rotateHeadset(targetAngle, selectedSpeed);
      },
      stopHeadset(){
        stopHeadset();
      },  
      moveBothHip(targetAngleL, targetAngleR, selectedSpeed){
        moveBothHip(targetAngleL, targetAngleR, selectedSpeed);
      },
      moveOneHip(selectedHip, targetAngle, selectedSpeed){
        moveOneHip(selectedHip, targetAngle, selectedSpeed);
      },      
      tiltBody(direction, targetAngle){
        tiltBody(direction, targetAngle);
      },
      stopHip(){
        stopHip();
      },  
      spinDegrees(direction, targetAngle, selectedSpeed){
        spinDegrees(direction, targetAngle, selectedSpeed);
      },
      moveDistance(direction, distance, selectedSpeed){
        moveDistance(direction, distance, selectedSpeed);
      },
      moveAlongTurningRadius(direction, angle, radius, selectedSpeed){
        moveAlongTurningRadius(direction, angle, radius, selectedSpeed);
      },    
      stopWheel(task){
        stopWheel(task);
      },        
      // stopAllMovement(){
      //   stopAllMovement();
      // },      
      initPose(){
        initPose();
      },  
      // sensing blocks
      startWakewordDetection(){
        startWakewordDetection();
      },
      stopWakewordDetection(){
        stopWakewordDetection();
      },
      startPersonDetection(){
        startPersonDetection();
      },
      // stopPersonDetection(){
      //   stopPersonDetection();
      // },
      startFaceDetection(){
        startFaceDetection();
      },
      // startFallDownDetection(){
      //   startFallDownDetection();
      // },      
      // startPickupDetection(){
      //   startPickupDetection();
      // },      
      startGetHeadsetAngle(){
        startGetHeadsetAngle();
      },
      startGetHipAngle(){
        startGetHipAngle();
      },
      startGetWheelSpeed(){
        startGetWheelSpeed();
      },
      startGetBatteryInfo(){
        startGetBatteryInfo();
      },          
      // navi blocks
      startPrepareNavi(){
        startPrepareNavi();
      },     
      startCreateMap(){
        startCreateMap();
      },
      cancelCreateMap(){
        cancelCreateMap();
      },
      startGoHome(){
        startGoHome();
      },
      cancelGoHome(){
        cancelGoHome();
      },
      startDockToCharger(){
        startDockToCharger();
      },
      cancelDockToCharger(){
        cancelDockToCharger();
      },
      startUndockFromCharger(){
        startUndockFromCharger();
      },
      cancelUndockFromCharger(){
        cancelUndockFromCharger();
      },
      startExploreNodes(){
        startExploreNodes();
      },
      cancelExploreNodes(){
        cancelExploreNodes();
      },
      startNavigateToSpace(spaceIdx){
        startNavigateToSpace(spaceIdx);
      },
      cancelNavigateToSpace(){
        cancelNavigateToSpace();
      },
      startSpinToFindPerson(userIdx){
        startSpinToFindPerson(userIdx);
      },
      cancelSpinToFindPerson(){
        cancelSpinToFindPerson();
      },
      startContactEyes(userIdx){
        startContactEyes(userIdx);
      },
      cancelContactEyes(){
        cancelContactEyes();
      },
      startComeHere(userIdx){
        startComeHere(userIdx);
      },
      cancelComeHere(){
        cancelComeHere();
      },
      startFollowMe(userIdx){
        startFollowMe(userIdx);
      },
      cancelFollowMe(){
        cancelFollowMe();
      },
      startNaviTask(task, idx){
        startNaviTask(task, idx);
      },
      cancelNaviTask(){
        cancelNaviTask();
      },
      // send files
      sendFiles(fileType, files){
        sendFiles(fileType, files);
      },  
      // disconnect robot
      disconnectRobot(){
        disconnectRobot();
      }
    };
  }, []);      

  useEffect(() => {
    if(lastJsonMessage) handleMessage(lastJsonMessage);
  }, [lastJsonMessage]);

  function sendJsonMsgViaWss(msg) {    
    if(IS_LOGGING_ON) console.log('send message:', msg);
    sendJsonMessage(msg);
  }  

  function disconnectRobot(){
    let msg = {
      msgId: 'disconnect_robot'
    }
    sendJsonMsgViaWss(msg);
  }

  function startCode(){
    let msg = {
      msgId: 'start_code'
    }
    sendJsonMsgViaWss(msg);
  }

  function playFacialExpression(facialExpression, repeat) {
    let msg = {
      msgId: 'display_emotion',
      index: facialExpression,
      loop: repeat === 1 ? true : false,
      instant_play: true
    }
    sendJsonMsgViaWss(msg);
  }  

  function displayImage(image) {
    let msg = {
      msgId: 'display_image',
      name: image,
    }
    sendJsonMsgViaWss(msg);
  }

  function displayText(text) {    
    let msg = {
      msgId: 'display_text',
      text_value: text, 
    }
    sendJsonMsgViaWss(msg);
  }

  function displayOnOff(onOff) {    
    let msg = {
      msgId: 'display_onoff',
      onoff: onOff === 1 ? true : false
    }
    sendJsonMsgViaWss(msg);
  }

  function setBrightness(level) {    
    let msg = {
      msgId: 'set_brightness',
      level: level
    }
    sendJsonMsgViaWss(msg);
  }

  function updateToastMessage(text) {    
    let msg = {
      msgId: 'update_toast_message',
      text_value: text, 
    }
    sendJsonMsgViaWss(msg);
  }

  function dismissToastMessage() {    
    let msg = {
      msgId: 'dismiss_toast_message',
    }
    sendJsonMsgViaWss(msg);
  }

  function playEmotionSound(sound) {
    let msg = {
      msgId: 'play_emotion_sound',
      index: sound, 
    }
    sendJsonMsgViaWss(msg);
  }

  function playEffectSound(sound) {
    let msg = {
      msgId: 'play_emotion_sound',
      index: sound, 
    }
    sendJsonMsgViaWss(msg);
  }

  function playSound(sound) {
    let msg = {
      msgId: 'play_sound',
      name: sound, 
    }
    sendJsonMsgViaWss(msg);
  }

  function playTTS(text) {        
    let msg = {
      msgId: 'play_tts',
      text_value: text, 
    }
    sendJsonMsgViaWss(msg);
  }

  function stopSound(type) {
    let msg = {
      msgId: 'stop_sound',
      type: type
    }
    sendJsonMsgViaWss(msg);
  }    

  function startSTT() {    
    let msg = {
      msgId: 'start_stt',
    }
    sendJsonMsgViaWss(msg);
  }

  // function startVoiceRecording() {    
  //   let msg = {
  //     msgId: 'start_voice_recording'
  //   }
  //   sendJsonMsgViaWss(msg);
  // }

  function volumeControl(vol) {    
    let msg = {
      msgId: 'set_volume',
      level: vol
    }
    sendJsonMsgViaWss(msg);
  }

  function startMotion(motionId) {
    let msg = {
      msgId: 'start_motion',
      motion_num: motionId,
      repeat_mode: 2,
      move_mode: 0
    }
    sendJsonMsgViaWss(msg);
  }

  function startInPlaceMotion(motionId, repeat) {
    let msg = {
      msgId: 'start_motion',
      motion_num: motionId,
      repeat_mode: repeat,
      move_mode: 4
    }
    sendJsonMsgViaWss(msg);
  }

  function stopMotion() {
    let msg = {
      msgId: 'stop_motion'
    }
    sendJsonMsgViaWss(msg);
  }       

  function rotateHeadset(targetAngle, selectedSpeed) {   
    let targetAngleOfHeadset = targetAngle; 
    let targetSpeed;
    switch(selectedSpeed){
      case 'slow':
        targetSpeed = constants.HEADSET_DPS_SPEED_SLOW;
        break;
      case 'normal':
        targetSpeed = constants.HEADSET_DPS_SPEED_NORMAL;
        break;
      case 'fast':
        targetSpeed = constants.HEADSET_DPS_SPEED_FAST;
        break;
      case 'fastest':
        targetSpeed = constants.HEADSET_DPS_SPEED_FASTEST;
        break;
      default:
        targetSpeed = constants.HEADSET_DPS_SPEED_NORMAL;
    }
    let msg = {
      msgId: 'headset_motion',
      target_angle: targetAngleOfHeadset, 
      // time_allowance: seconds
      speed: targetSpeed
    }
    sendJsonMsgViaWss(msg);
  }

  function stopHeadset() {
    let msg = {
      msgId: 'stop_headset'
    }
    sendJsonMsgViaWss(msg);
  }    

  function moveBothHip(targetAngleL, targetAngleR, selectedSpeed) {
    let targetAngleOfLeftHip = targetAngleL;
    let targetAngleOfRightHip = targetAngleR;    
    let targetSpeed;
    switch(selectedSpeed){
      case 'slow':
        targetSpeed = constants.HIP_DPS_SPEED_SLOW;
        break;
      case 'normal':
        targetSpeed = constants.HIP_DPS_SPEED_NORMAL;
        break;
      case 'fast':
        targetSpeed = constants.HIP_DPS_SPEED_FAST;
        break;
      default:
        targetSpeed = constants.HIP_DPS_SPEED_NORMAL;
    }

    let msg = {
      msgId: 'both_hip_motion',
      left_target: targetAngleOfLeftHip, 
      right_target: targetAngleOfRightHip, 
      speed: targetSpeed
    }
    sendJsonMsgViaWss(msg);
  }

  function moveOneHip(selectedHip, targetAngle, selectedSpeed) {
    let hipSide = selectedHip === 'left' ? 0 : 1;
    let targetAngleOfSelectedHip = targetAngle;    
    let targetSpeed;
    switch(selectedSpeed){
      case 'slow':
        targetSpeed = constants.HIP_DPS_SPEED_SLOW;
        break;
      case 'normal':
        targetSpeed = constants.HIP_DPS_SPEED_NORMAL;
        break;
      case 'fast':
        targetSpeed = constants.HIP_DPS_SPEED_FAST;
        break;
      default:
        targetSpeed = constants.HIP_DPS_SPEED_NORMAL;
    }

    let msg = {
      msgId: 'one_hip_motion',
      side: hipSide,
      target: targetAngleOfSelectedHip, 
      speed: targetSpeed 
    }
    sendJsonMsgViaWss(msg);
  }  

  function tiltBody(direction, targetAngle) { 
    let tiltDirection;
    direction === 'left' ? tiltDirection = 0 : tiltDirection = 1;   // left : 0, right : 1
    // let targetSpeed = constants.BODY_SPEED_NORMAL;

    let msg = {
      msgId: 'body_roll',
      body_target_direction: tiltDirection,
      body_target_angle: targetAngle,
      // body_target_speed: targetSpeed     // body 각도 1도 당 hip 각도 약 5~10도 정도 변화. Hip speed 5~10dps
    }
    sendJsonMsgViaWss(msg);
  }

  function stopHip() {
    let msg = {
      msgId: 'stop_hip'
    }
    sendJsonMsgViaWss(msg);
  }     

  function spinDegrees(direction, targetAngle, selectedSpeed) {    
    let targetSpeed, angle;
    switch(selectedSpeed){
      case 'slow':
        targetSpeed = constants.WHEEL_ANGULAR_SPEED_SLOW_RAD;
        break;
      case 'normal':
        targetSpeed = constants.WHEEL_ANGULAR_SPEED_NORMAL_RAD;
        break;
      case 'fast':
        targetSpeed = constants.WHEEL_ANGULAR_SPEED_FAST_RAD;
        break;
      case 'fastest':
        targetSpeed = constants.WHEEL_ANGULAR_SPEED_FASTEST_RAD;
        break;
      default:
        targetSpeed = constants.WHEEL_ANGULAR_SPEED_FAST_RAD;
    }        
    // let wheelAngularSpeed;
    // direction === 'left' ? wheelAngularSpeed = -targetSpeed : wheelAngularSpeed = targetSpeed;
    direction === 'left' ? angle = targetAngle : angle = -targetAngle;

    let msg = {
      msgId: 'wheel_move_spin',
      speed: targetSpeed, 
      spin_angle: angle
    }
    sendJsonMsgViaWss(msg);
  }

  function moveDistance(direction, distance, selectedSpeed) {
    let targetSpeed;
    switch(selectedSpeed){
      case 0:
        targetSpeed = constants.WHEEL_LINEAR_SPEED_SLOW;
        break;
      case 1:
        targetSpeed = constants.WHEEL_LINEAR_SPEED_NORMAL;
        break;
      case 2:
        targetSpeed = constants.WHEEL_LINEAR_SPEED_FAST;
        break;
      case 3:
        targetSpeed = constants.WHEEL_LINEAR_SPEED_FASTEST;
        break;
      default:
        targetSpeed = constants.WHEEL_LINEAR_SPEED_NORMAL;
    }        
    let wheelLinearSpeed;
    direction === 'forward' ? wheelLinearSpeed = targetSpeed : wheelLinearSpeed = -targetSpeed;
    
    let msg = {
      msgId: 'wheel_move_linear',
      speed: wheelLinearSpeed, 
      move_distance: distance, 
    }
    sendJsonMsgViaWss(msg);
  }

  function moveAlongTurningRadius(direction, angle, radius, selectedSpeed) {
    let wheelLinearSpeed, targetAngle;
    switch(selectedSpeed){
      case 0:
        wheelLinearSpeed = constants.WHEEL_LINEAR_SPEED_SLOW;
        break;
      case 1:
        wheelLinearSpeed = constants.WHEEL_LINEAR_SPEED_NORMAL;
        break;
      case 2:
        wheelLinearSpeed = constants.WHEEL_LINEAR_SPEED_FAST;
        break;
      case 3:
        wheelLinearSpeed = constants.WHEEL_LINEAR_SPEED_FASTEST;
        break;
      default:
        wheelLinearSpeed = constants.WHEEL_LINEAR_SPEED_NORMAL;
    }        
    // let wheelAngularSpeed = wheelLinearSpeed / radius;
    // wheelAngularSpeed = Number(wheelAngularSpeed.toFixed(2));
    
    direction === 'clockwise' ? targetAngle = -angle : targetAngle = angle;
    
    let msg = {
      msgId: 'wheel_move_along_turning_radius',
      speed: wheelLinearSpeed, 
      spin_angle: targetAngle,
      radius: radius
    }
    sendJsonMsgViaWss(msg);
  }

  function stopWheel(task) {
    let msg = {
      msgId: 'stop_wheel',
      task: task
    }
    sendJsonMsgViaWss(msg);
  }    

  // function stopAllMovement() {
  //   stopHeadset();
  //   stopHip();
  //   stopWheel(); 
  // }    

  function initPose() {
    Logger('initPose');   
    let msg = {
      msgId: 'headset_motion',
      target_angle: constants.HEADSET_ANGLE_NEUTRAL_POSITION, 
      // time_allowance: constants.HEADSET_DEFAULT_TIME_ALLOWANCE
      speed: constants.HEADSET_DPS_SPEED_NORMAL
    }
    sendJsonMsgViaWss(msg);
    msg = {
      msgId: 'both_hip_motion',
      left_target: constants.HIP_ANGLE_NEUTRAL_POSITION, 
      right_target: constants.HIP_ANGLE_NEUTRAL_POSITION, 
      speed: constants.HIP_DPS_SPEED_NORMAL
    }
    // let msg = {
    //   msgId: 'init_pose'
    // }
    sendJsonMsgViaWss(msg);
  }      

  function startWakewordDetection() {
    let msg = {
      msgId: 'start_wakeword_detection'
    }
    sendJsonMsgViaWss(msg);
  }

  function stopWakewordDetection() {
    let msg = {
      msgId: 'stop_wakeword_detection'
    }
    sendJsonMsgViaWss(msg);
  }

  function startPersonDetection() {
    let msg = {
      msgId: 'start_person_detection',
    }
    sendJsonMsgViaWss(msg);
  } 

  // function stopPersonDetection() {
  //   let msg = {
  //     msgId: 'stop_person_detection',
  //   }
  //   sendJsonMsgViaWss(msg);
  // } 

  function startFaceDetection() {
    let msg = {
      msgId: 'start_face_detection',
    }
    sendJsonMsgViaWss(msg);
  } 

  // function startFallDownDetection() {
  //   let msg = {
  //     msgId: 'start_falldown_detection',
  //   }
  //   sendJsonMsgViaWss(msg);
  // }

  // function startPickupDetection() {
  //   let msg = {
  //     msgId: 'start_pickup_detection',
  //   }
  //   sendJsonMsgViaWss(msg);
  // }  

  function startGetHeadsetAngle() {
    let msg = {
      msgId: 'start_get_headset_angle',
    }
    sendJsonMsgViaWss(msg);
  }

  function startGetHipAngle() {
    let msg = {
      msgId: 'start_get_hip_angle',
    }
    sendJsonMsgViaWss(msg);
  }

  function startGetWheelSpeed() {
    let msg = {
      msgId: 'start_get_wheel_speed',
    }
    sendJsonMsgViaWss(msg);
  }

  function startGetBatteryInfo() {
    let msg = {
      msgId: 'start_get_battery_info',
    }
    sendJsonMsgViaWss(msg);
  }     

  function startPrepareNavi() {
    let msg = {
      msgId: 'start_prepare_navi',
    }
    sendJsonMsgViaWss(msg);
  }

  function startCreateMap() {
    let msg = {
      msgId: 'start_create_map',
    }
    sendJsonMsgViaWss(msg);
  }
  function cancelCreateMap() {
    let msg = {
      msgId: 'cancel_create_map',
    }
    sendJsonMsgViaWss(msg);
  }

  function startGoHome() {
    let msg = {
      msgId: 'start_go_home',
    }
    sendJsonMsgViaWss(msg);
  }

  function cancelGoHome() {
    let msg = {
      msgId: 'cancel_go_home',
    }
    sendJsonMsgViaWss(msg);
  }

  function startDockToCharger() {
    let msg = {
      msgId: 'start_dock_to_charger',
    }
    sendJsonMsgViaWss(msg);
  }

  function cancelDockToCharger() {
    let msg = {
      msgId: 'cancel_dock_to_charger',
    }
    sendJsonMsgViaWss(msg);
  }

  function startUndockFromCharger() {
    let msg = {
      msgId: 'start_undock_from_charger',
    }
    sendJsonMsgViaWss(msg);
  }

  function cancelUndockFromCharger() {
    let msg = {
      msgId: 'cancel_undock_from_charger',
    }
    sendJsonMsgViaWss(msg);
  }

  function startExploreNodes() {
    let msg = {
      msgId: 'start_explore_nodes',
    }
    sendJsonMsgViaWss(msg);
  }

  function cancelExploreNodes() {
    let msg = {
      msgId: 'cancel_explore_nodes',
    }
    sendJsonMsgViaWss(msg);
  }

  function startNavigateToSpace(spaceIdx) {
    let msg = {
      msgId: 'start_navigate_to_space',
      spaceIdx: spaceIdx
    }
    sendJsonMsgViaWss(msg);
  }

  function cancelNavigateToSpace() {
    let msg = {
      msgId: 'cancel_navigate_to_space',
    }
    sendJsonMsgViaWss(msg);
  }

  function startSpinToFindPerson(userIdx) {
    let msg = {
      msgId: 'start_spin_to_find_person',
      userIdx: userIdx
    }
    sendJsonMsgViaWss(msg);
  } 

  function cancelSpinToFindPerson() {
    let msg = {
      msgId: 'cancel_spin_to_find_person',
    }
    sendJsonMsgViaWss(msg);
  } 

  function startContactEyes(userIdx) {
    let msg = {
      msgId: 'start_contact_eyes',
      userIdx: userIdx
    }
    sendJsonMsgViaWss(msg);
  }

  function cancelContactEyes() {
    let msg = {
      msgId: 'cancel_contact_eyes',
    }
    sendJsonMsgViaWss(msg);
  }

  function startComeHere(userIdx) {
    let msg = {
      msgId: 'start_come_here',
      userIdx: userIdx
    }
    sendJsonMsgViaWss(msg);
  }

  function cancelComeHere() {
    let msg = {
      msgId: 'cancel_come_here'
    }
    sendJsonMsgViaWss(msg);
  }

  function startFollowMe(userIdx) {
    let msg = {
      msgId: 'start_follow_me',
      userIdx: userIdx
    }
    sendJsonMsgViaWss(msg);
  }

  function cancelFollowMe() {
    let msg = {
      msgId: 'cancel_follow_me'
    }
    sendJsonMsgViaWss(msg);
  }

  function startNaviTask(task, idx){
    let msg = {
      msgId: 'start_' + task
    }
    if(task === 'spin_to_find_person' || task === 'contact_eyes' || task === 'come_here' || task === 'follow_me') {
      msg.userIdx = idx;
    } else if(task === 'navigate_to_space'){
      msg.spaceIdx = idx;
    }
    sendJsonMsgViaWss(msg);
  }

  function cancelNaviTask(){
    let msg = {
      msgId: 'cancel_navi_task'
    }
    sendJsonMsgViaWss(msg);
  }

  function sendFiles(fileType, files){ 
    let fileCounter = files.length;
    Array.from(files).forEach((file) => {      
      const reader = new FileReader();
      reader.onload = () => {
        const base64Data = reader.result.split(',')[1];
        let msg = {
          msgId: fileType === 'image' ? 'send_image_files' : 'send_audio_files',
          filename: file.name,
          data: base64Data,
          filenumber: fileCounter--
        }        
        sendJsonMsgViaWss(msg);
      };
      reader.readAsDataURL(file);
    });    
  }  

  function handlePlaySoundFinishedTopic(){
    onSoundFinished();
  }

  function handleSttFinished(msg){
    if (msg.text) onSttFinished(msg.text);
  }

  // function handleVoiceRecordingFinishedTopic(msg){
  //   onVoiceRecordingFinished();
  //   if(msg?.audioList){
  //     const audioList = msg.audioList;   
  //     const newAudioList = Array.from(audioList).map((filename) => {
  //       const filenameSplit = filename.split('.')[0];    // 확장자 제거
  //       // let uiname = filenameSplit.split('_')[2];
  //       // uiname = `${uiname.charAt(0).toUpperCase()}${uiname.slice(1).toLowerCase()}`
  //       // return [uiname, filenameSplit];
  //       return [filenameSplit, filenameSplit];
  //     })
  //     setAudioFileList(newAudioList);
  //   }    
  // }

  function handleMotionFinishedTopic(){
    onMotionFinished();
  }

  function handleHeadsetMotionFinishedTopic(){
    onHeadsetMotionFinished(); 
  }

  function handleHipMotionFinishedTopic(){
    onHipMotionFinished();
  }

  function handleWheelMoveFinishedTopic(){
    onWheelMoveFinished();
  }

  function handleWakewordDetectionTopic(msg){    
    const receivedTime = Date.now();
    Logger(`receivedTime: ${receivedTime}`);
    onUpdateWakeWordDetectionStatus(receivedTime);
  }

  function handlePersonDetectionTopic(msg){
    const isDetected = msg.isDetected === 'true';
    onUpdatePersonDetectionStatus(isDetected);
  }

  function handleFaceDetectionTopic(msg){
    onUpdateFaceDetectionStatus(msg.detectedUserList);
  }

  // function handleFalldownDetectionTopic(msg){
  //   onUpdateFalldownStatus(msg.isDetected);
  // }

  // function handlePickupDetectionTopic(msg){
  //   onUpdatePickupStatus(msg.isDetected);      
  // }

  function handleHeadsetAngleTopic(msg){
    onUpdateAngleOfHeadset(msg.angle);
  }

  function handleHipAngleTopic(msg){
    onUpdateAngleOfHip(msg);
  }

  function handleWheelSpeedTopic(msg){
    onUpdateSpeedOfWheel(msg);
  }

  function handleBatteryInfoTopic(msg){
    onUpdateBatteryLevel(msg.battery_remain);
  }

  function handlePrepareNaviStatusTopic(msg){
    const isSucceeded = msg.result === 'true';
    onUpdateNaviStatus(isSucceeded);
  }

  function handleCreateMapStatusTopic(msg){
    const isSucceeded = msg.result === 'true';
    onUpdateCreateMapStatus(isSucceeded);
  }

  function handleIsAtHomeStatusTopic(msg){
    const isSucceeded = msg.result === 'true';
    onUpdateIsAtHomeStatus(isSucceeded);
  }

  function handleDockToChargerStatusTopic(msg){
    const isSucceeded = msg.result === 'true';
    onUpdateDockToChargerStatus(isSucceeded);
  }

  function handleUndockFromChargerStatusTopic(msg){
    const isSucceeded = msg.result === 'true';
    onUpdateUndockFromChargerStatus(isSucceeded);
  }

  function handleExploreNodesStatusTopic(msg){
    const isSucceeded = msg.result === 'true';
    onUpdateExploreNodesStatus(isSucceeded);
  }

  function handleNavigateToSpaceStatusTopic(msg){
    const isSucceeded = msg.result === 'true';
    onUpdateNavigateToSpaceStatus(isSucceeded);
  }

  function handleSpinToFindPersonStatusTopic(msg){
    const isSucceeded = msg.result === 'true';
    onUpdateSpinToFindPersonStatus(isSucceeded);
  }

  function handleContactEyesStatusTopic(msg){
    const isSucceeded = msg.result;
    onUpdateEyeContactStatus(isSucceeded);
  }

  function handleComeHereStatusTopic(msg){
    const isSucceeded = msg.result === 'true';
    onUpdateComeHereStatus(isSucceeded);
  }

  function handleFollowMeStatusTopic(msg){
    const isSucceeded = msg.result === 'true';
    onUpdateFollowMeStatus(isSucceeded);
  }

  function handleNaviTaskCanceledTopic(msg){
    onUpdateNaviTaskCanceled();
  }

  function handleUpdateImageFileList(msg){
    if(msg?.imageList){
      const imageList = msg.imageList;
      const newImageList = Array.from(imageList).map((filename) => {
        const filenameSplit = filename.split('.')[0];   // 확장자 제거
        // let uiname = filenameSplit.split('_');
        // uiname = uiname.slice(1).join('_');
        // uiname = `${uiname.charAt(0).toUpperCase()}${uiname.slice(1).toLowerCase()}`
        // return [uiname, filenameSplit];
        return [filenameSplit, filename];
      })      
      setImageFileList(newImageList);
    }    
    onUpdateSendFileResult();
  }

  function handleUpdateAudioFileList(msg){
    if(msg?.audioList){
      const audioList = msg.audioList;   
      const newAudioList = Array.from(audioList).map((filename) => {
        const filenameSplit = filename.split('.')[0];    // 확장자 제거
        // let uiname = filenameSplit.split('_')[2];
        // uiname = `${uiname.charAt(0).toUpperCase()}${uiname.slice(1).toLowerCase()}`
        // return [uiname, filenameSplit];
        return [filenameSplit, filename];
      })      
      setAudioFileList(newAudioList);
    }
    onUpdateSendFileResult();
  }

  function handleMessage(msg) {
    if(IS_LOGGING_ON) console.log('received message:', msg);
    let msgId;
    Object.keys(msg).includes('msgId') ? msgId = msg.msgId : msgId = 'noId';
    switch(msgId){      
      case 'play_sound_finished':
        handlePlaySoundFinishedTopic();
        break;
      case 'stt_finished':
        handleSttFinished(msg);
        break;
      // case 'voice_recording_finished':
      //   handleVoiceRecordingFinishedTopic(msg);
      //   break;
      case 'motion_finished':
        handleMotionFinishedTopic();
        break;
      case 'headset_motion_finished':
        handleHeadsetMotionFinishedTopic();
        break;
      case 'hip_motion_finished':
        handleHipMotionFinishedTopic();
        break;
      case 'wheel_move_finished':
        handleWheelMoveFinishedTopic();
        break;    
      case 'wakeword_detection':
        handleWakewordDetectionTopic(msg);
        break;
      case 'person_detection':
        handlePersonDetectionTopic(msg);
        break;  
      case 'face_detection':
        handleFaceDetectionTopic(msg);
        break;  
      // case 'falldown_detection':
      //   handleFalldownDetectionTopic(msg);
      //   break;   
      // case 'pickup_detection':
      //   handlePickupDetectionTopic(msg);
      //   break;     
      case 'headset_angle':
        handleHeadsetAngleTopic(msg);
        break;
      case 'hip_angle':
        handleHipAngleTopic(msg);
        break;
      case 'wheel_speed':
        handleWheelSpeedTopic(msg);
        break;
      case 'battery_info':
        handleBatteryInfoTopic(msg);
        break;
      case 'prepare_navi_status':
        handlePrepareNaviStatusTopic(msg);
        break;
      case 'create_map_status':
        handleCreateMapStatusTopic(msg);
        break;
      case 'is_athome_status':
        handleIsAtHomeStatusTopic(msg);
        break;
      case 'dock_to_charger_status':
        handleDockToChargerStatusTopic(msg);
        break;
      case 'undock_from_charger_status':
        handleUndockFromChargerStatusTopic(msg);
        break;
      case 'explore_nodes_status':
        handleExploreNodesStatusTopic(msg);
        break;
      case 'navigate_to_space_status':
        handleNavigateToSpaceStatusTopic(msg);
        break;
      case 'spin_to_find_person_status':
        handleSpinToFindPersonStatusTopic(msg);
        break;
      case 'contact_eyes_status':
        handleContactEyesStatusTopic(msg);
        break;
      case 'come_here_status':
        handleComeHereStatusTopic(msg);
        break;
      case 'follow_me_status':
        handleFollowMeStatusTopic(msg);
        break;
      case 'navi_task_canceled':
        handleNaviTaskCanceledTopic(msg);
        break;
      case 'update_image_file_list':
        handleUpdateImageFileList(msg);
        break;
      case 'update_audio_file_list':
        handleUpdateAudioFileList(msg);
        break;
      default:
        Logger('received topic that cannot handle');
    }
  }

  return 
});

export default WsConnector;