import { Button } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledButton = styled(Button)(({theme}) => ({    
  width: "110px",
  height: "35px",
  fontSize: "14px", 
  fontWeight: 300 
}));

export default StyledButton;