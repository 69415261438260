import { Outlet } from 'react-router-dom';
// @mui
import { Stack } from '@mui/material';
import { styled } from '@mui/material/styles';
// components
import MainFooter from './MainFooter';
import MainHeader from './MainHeader';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  position: 'relative',
  display: 'flex',
	// width: '100%',
	overflow: 'hidden'
	// width: '100%',
}));

export default function MainLayout() {

  return (
		<RootStyle>
		
      <Stack sx={{ minHeight: 1 }}>    
      { /* <MainHeader /> */ }

      <Outlet />

      <MainFooter />
      </Stack>
    </RootStyle>
  );
}
