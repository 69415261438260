import React, { useContext } from "react";

// context
import { robotConnContext } from '../contexts/ConnectContext';

// sections
import HomeIntro from "./sections/HomeIntro";
import HomeConfig from "./sections/HomeConfig";
import CreateOrEdit from "./sections/CreateOrEdit";

export default function Home() {
	
	const { isReadyContext } = useContext(robotConnContext);
	
  return (
		<>		
		<HomeIntro/>
		<HomeConfig/>
		{isReadyContext &&
			<CreateOrEdit/>
		}
		</>
  );
}