import { javascriptGenerator } from "blockly/javascript";
import { Order } from 'blockly/javascript';

javascriptGenerator.forBlock['c_loop_repeat_infinitely'] = function(block, generator) {	
	let addLoopTrap = 'setLoopTrapCounter();\n';
	let branch = generator.statementToCode(block, 'DO');
	branch += generator.INDENT + 'checkLoopTrapCounter(forceStopInterpreter());\n';
	const withStepUI = block.workspace.getBlocksByType('c_program_start', false)[0].getFieldValue('STEP') === 'TRUE';
	if(withStepUI) branch += generator.INDENT + `highlightBlock('${block.id}', true);\n`;
	const code = addLoopTrap + 'while(1){\n' + branch + '}\n';
	return code;
};

javascriptGenerator.forBlock['c_loop_repeat_until'] = function(block, generator) {
	let argument0 = generator.valueToCode(block, 'UNTIL', Order.LOGICAL_NOT);
	argument0 = '!' + argument0;

	let addLoopTrap = 'setLoopTrapCounter();\n';
	let branch = generator.statementToCode(block, 'DO');
	branch += generator.INDENT + 'checkLoopTrapCounter(forceStopInterpreter());\n';
	const withStepUI = block.workspace.getBlocksByType('c_program_start', false)[0].getFieldValue('STEP') === 'TRUE';
	if(withStepUI) branch += generator.INDENT + `highlightBlock('${block.id}', true);\n`;

  return addLoopTrap + 'while (' + argument0 + ') {\n' + branch + '}\n';
};

javascriptGenerator.forBlock['c_loop_repeat_while'] = function(block, generator) {
	let argument0 = generator.valueToCode(block, 'WHILE', Order.NONE);
	
	let addLoopTrap = 'setLoopTrapCounter();\n';
	let branch = generator.statementToCode(block, 'DO');
	branch += generator.INDENT + 'checkLoopTrapCounter(forceStopInterpreter());\n';
	const withStepUI = block.workspace.getBlocksByType('c_program_start', false)[0].getFieldValue('STEP') === 'TRUE';
	if(withStepUI) branch += generator.INDENT + `highlightBlock('${block.id}', true);\n`;
  return addLoopTrap + 'while (' + argument0 + ') {\n' + branch + '}\n';
};

javascriptGenerator.forBlock['c_loop_continue'] = function(block, generator) {
	let code = 'continue;\n';
	return code;
};

javascriptGenerator.forBlock['c_loop_break'] = function(block, generator) {
	let code = 'break;\n';
	return code;
};
