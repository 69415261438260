import Blockly from 'blockly/core';

const prepareNavigationJson = {
	"message0": "prepare navigation until done",
	"inputsInline": false,
	"previousStatement": null,
	"nextStatement": null,
	"style": "navigation_blocks",
}
Blockly.Blocks['c_navigation_prepare'] = {
	init: function() {
		this.jsonInit(prepareNavigationJson);
	}
}

const createMapJson = {	
	"inputsInline": false,
	"previousStatement": null,
	"nextStatement": null,
	"style": "navigation_blocks",
}
const createMapSyncJson = JSON.parse(JSON.stringify(createMapJson));
createMapSyncJson.message0 = "create map until done";
const createMapAsyncJson = JSON.parse(JSON.stringify(createMapJson));
createMapAsyncJson.message0 = "create map";
Blockly.Blocks['c_navigation_createMap_sync'] = {
  init: function() {
    this.jsonInit(createMapSyncJson);
  }
}
Blockly.Blocks['c_navigation_createMap_async'] = {
  init: function() {
    this.jsonInit(createMapAsyncJson);
  }
}

const cancelNaviJson = {	
	"inputsInline": false,
	"previousStatement": null,
	"nextStatement": null,
	"style": "navigation_blocks",
}

const cancelCreateMapJson = JSON.parse(JSON.stringify(cancelNaviJson));
cancelCreateMapJson.message0 = "cancel create map";
Blockly.Blocks['c_navigation_cancelCreateMap'] = {
	init: function() {
		this.jsonInit(cancelCreateMapJson);
	}
}

const goHomeJson = {
	"inputsInline": false,
	"previousStatement": null,
	"nextStatement": null,
	"style": "navigation_blocks",
}
const goHomeSyncJson = JSON.parse(JSON.stringify(goHomeJson));
goHomeSyncJson.message0 = "go home until done";
const goHomeAsyncJson = JSON.parse(JSON.stringify(goHomeJson));
goHomeAsyncJson.message0 = "go home";
Blockly.Blocks['c_navigation_goHome_sync'] = {
  init: function() {
    this.jsonInit(goHomeSyncJson);
  }
}
Blockly.Blocks['c_navigation_goHome_async'] = {
  init: function() {
    this.jsonInit(goHomeAsyncJson);
  }
}

const cancelGoHomeJson = JSON.parse(JSON.stringify(cancelNaviJson));
cancelGoHomeJson.message0 = "cancel go home";
Blockly.Blocks['c_navigation_cancelGoHome'] = {
	init: function() {
		this.jsonInit(cancelGoHomeJson);
	}
}

const dockToChargerJson = {
	"inputsInline": false,
	"previousStatement": null,
	"nextStatement": null,
	"style": "navigation_blocks",
}
const dockToChargerSyncJson = JSON.parse(JSON.stringify(dockToChargerJson));
dockToChargerSyncJson.message0 = "dock to charger until done";
const dockToChargerAsyncJson = JSON.parse(JSON.stringify(dockToChargerJson));
dockToChargerAsyncJson.message0 = "dock to charger";
Blockly.Blocks['c_navigation_dockToCharger_sync'] = {
  init: function() {
    this.jsonInit(dockToChargerSyncJson);
  }
}
Blockly.Blocks['c_navigation_dockToCharger_async'] = {
  init: function() {
    this.jsonInit(dockToChargerAsyncJson);
  }
}

const cancelDockToChargerJson = JSON.parse(JSON.stringify(cancelNaviJson));
cancelDockToChargerJson.message0 = "cancel dock to charger";
Blockly.Blocks['c_navigation_cancelDockToCharger'] = {
	init: function() {
		this.jsonInit(cancelDockToChargerJson);
	}
}

const undockFromChargerJson = {
	"inputsInline": false,
	"previousStatement": null,
	"nextStatement": null,
	"style": "navigation_blocks",
}
const undockFromChargerSyncJson = JSON.parse(JSON.stringify(undockFromChargerJson));
undockFromChargerSyncJson.message0 = "undock from charger until done";
const undockFromChargerAsyncJson = JSON.parse(JSON.stringify(undockFromChargerJson));
undockFromChargerAsyncJson.message0 = "undock from charger";
Blockly.Blocks['c_navigation_undockFromCharger_sync'] = {
  init: function() {
    this.jsonInit(undockFromChargerSyncJson);
  }
}
Blockly.Blocks['c_navigation_undockFromCharger_async'] = {
  init: function() {
    this.jsonInit(undockFromChargerAsyncJson);
  }
}

const cancelUndockFromChargerJson = JSON.parse(JSON.stringify(cancelNaviJson));
cancelUndockFromChargerJson.message0 = "cancel undock from charger";
Blockly.Blocks['c_navigation_cancelUndockFromCharger'] = {
	init: function() {
		this.jsonInit(cancelUndockFromChargerJson);
	}
}

const exploreNodesJson = {
	"inputsInline": false,
	"previousStatement": null,
	"nextStatement": null,
	"style": "navigation_blocks",
}
const exploreNodesSyncJson = JSON.parse(JSON.stringify(exploreNodesJson));
exploreNodesSyncJson.message0 = "explore nodes in map until done";
const exploreNodesAsyncJson = JSON.parse(JSON.stringify(exploreNodesJson));
exploreNodesAsyncJson.message0 = "explore nodes in map";
Blockly.Blocks['c_navigation_exploreNodes_sync'] = {
  init: function() {
    this.jsonInit(exploreNodesSyncJson);
  }
}
Blockly.Blocks['c_navigation_exploreNodes_async'] = {
  init: function() {
    this.jsonInit(exploreNodesAsyncJson);
  }
}

const cancelExploreNodesJson = JSON.parse(JSON.stringify(cancelNaviJson));
cancelExploreNodesJson.message0 = "cancel explore nodes";
Blockly.Blocks['c_navigation_cancelExploreNodes'] = {
	init: function() {
		this.jsonInit(cancelExploreNodesJson);
	}
}

const cancelNavigateToSpaceJson = JSON.parse(JSON.stringify(cancelNaviJson));
cancelNavigateToSpaceJson.message0 = "cancel navigate to space";
Blockly.Blocks['c_navigation_cancelNavigateToSpace'] = {
	init: function() {
		this.jsonInit(cancelNavigateToSpaceJson);
	}
}

const cancelSpinToFindPersonJson = JSON.parse(JSON.stringify(cancelNaviJson));
cancelSpinToFindPersonJson.message0 = "cancel spin to find person";
Blockly.Blocks['c_navigation_cancelSpinToFindPerson'] = {
	init: function() {
		this.jsonInit(cancelSpinToFindPersonJson);
	}
}

const cancelContactEyesJson = JSON.parse(JSON.stringify(cancelNaviJson));
cancelContactEyesJson.message0 = "cancel make eye contact";
Blockly.Blocks['c_navigation_cancelContactEyes'] = {
	init: function() {
		this.jsonInit(cancelContactEyesJson);
	}
}

const cancelComeHereJson = JSON.parse(JSON.stringify(cancelNaviJson));
cancelComeHereJson.message0 = "cancel come to person";
Blockly.Blocks['c_navigation_cancelComeHere'] = {
	init: function() {
		this.jsonInit(cancelComeHereJson);
	}
}

const cancelFollowMeJson = JSON.parse(JSON.stringify(cancelNaviJson));
cancelFollowMeJson.message0 = "cancel follow person";
Blockly.Blocks['c_navigation_cancelFollowMe'] = {
	init: function() {
		this.jsonInit(cancelFollowMeJson);
	}
}

const cancelNaviTaskJson = JSON.parse(JSON.stringify(cancelNaviJson));
cancelNaviTaskJson.message0 = "cancel running navigation task";
Blockly.Blocks['c_navigation_cancelNaviTask'] = {
	init: function() {
		this.jsonInit(cancelNaviTaskJson);
	}
}