import { Link as RouterLink } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Button, Typography, Container } from '@mui/material';
// assets
import { MemorizedPageNotFoundIllustration as PageNotFoundIllustration} from '../assets/images/illustration_404';

// ----------------------------------------------------------------------

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

export default function NotFound() {
  return (
    <Container component="section" sx={{ mt: 8, mb: 4 }}>			
      <ContentStyle sx={{ textAlign: 'center', alignItems: 'center' }}>
        <Typography variant="h3" paragraph>
          Sorry, page not found!
        </Typography>

        <Typography sx={{ color: 'text.secondary' }}>
          Sorry, we couldn’t find the page you’re looking for. Perhaps you’ve mistyped the URL? Be sure to check
          your spelling.
        </Typography>

        <PageNotFoundIllustration sx={{ height: 260, my: { xs: 5, sm: 10 }, color:'secondary' }} />
        <Button component={RouterLink} to="/" size="large" variant="contained" color='secondary'>
          Go to Home
        </Button>
      </ContentStyle>
    </Container>
  );
}