export const toolboxMotion = {
  'kind': 'category',
  'name': 'Motion',
  'categorystyle': 'motion_category',
  'contents': [
    {
      'kind': 'block',
      'type': 'c_motion_awe_sync'
    },
    {
      'kind': 'block',
      'type': 'c_motion_awe_async'
    },
    {
      'kind': 'block',
      'type': 'c_motion_exciting_sync'
    },
    {
      'kind': 'block',
      'type': 'c_motion_exciting_async'
    },
    {
      'kind': 'block',
      'type': 'c_motion_surprise_sync'
    },
    {
      'kind': 'block',
      'type': 'c_motion_surprise_async'
    },
    {
      'kind': 'block',
      'type': 'c_motion_anticipation_sync'
    },
    {
      'kind': 'block',
      'type': 'c_motion_anticipation_async'
    },
    {
      'kind': 'block',
      'type': 'c_motion_confident_sync'
    },
    {
      'kind': 'block',
      'type': 'c_motion_confident_async'
    },
    {
      'kind': 'block',
      'type': 'c_motion_love_sync'
    },
    {
      'kind': 'block',
      'type': 'c_motion_love_async'
    },
    {
      'kind': 'block',
      'type': 'c_motion_delight_sync'
    },
    {
      'kind': 'block',
      'type': 'c_motion_delight_async'
    },
    {
      'kind': 'block',
      'type': 'c_motion_joy_sync'
    },
    {
      'kind': 'block',
      'type': 'c_motion_joy_async'
    },
    {
      'kind': 'block',
      'type': 'c_motion_curiosity_sync'
    },
    {
      'kind': 'block',
      'type': 'c_motion_curiosity_async'
    },
    {
      'kind': 'block',
      'type': 'c_motion_relieved_sync'
    },
    {
      'kind': 'block',
      'type': 'c_motion_relieved_async'
    },
    {
      'kind': 'block',
      'type': 'c_motion_satisfied_sync'
    },
    {
      'kind': 'block',
      'type': 'c_motion_satisfied_async'
    },
    {
      'kind': 'block',
      'type': 'c_motion_touched_sync'
    },
    {
      'kind': 'block',
      'type': 'c_motion_touched_async'
    },
    {
      'kind': 'block',
      'type': 'c_motion_agree_sync'
    },
    {
      'kind': 'block',
      'type': 'c_motion_agree_async'
    },
    {
      'kind': 'block',
      'type': 'c_motion_shyness_sync'
    },
    {
      'kind': 'block',
      'type': 'c_motion_shyness_async'
    },
    {
      'kind': 'block',
      'type': 'c_motion_proud_sync'
    },
    {
      'kind': 'block',
      'type': 'c_motion_proud_async'
    },
    {
      'kind': 'block',
      'type': 'c_motion_interested_sync'
    },
    {
      'kind': 'block',
      'type': 'c_motion_interested_async'
    },
    {
      'kind': 'block',
      'type': 'c_motion_playful_sync'
    },
    {
      'kind': 'block',
      'type': 'c_motion_playful_async'
    },
    {
      'kind': 'block',
      'type': 'c_motion_tremble_sync'
    },
    {
      'kind': 'block',
      'type': 'c_motion_tremble_async'
    },
    {
      'kind': 'block',
      'type': 'c_motion_thrilled_sync'
    },
    {
      'kind': 'block',
      'type': 'c_motion_thrilled_async'
    },
    {
      'kind': 'block',
      'type': 'c_motion_thinking_sync'
    },
    {
      'kind': 'block',
      'type': 'c_motion_thinking_async'
    },
    {
      'kind': 'block',
      'type': 'c_motion_dizzy_sync'
    },
    {
      'kind': 'block',
      'type': 'c_motion_dizzy_async'
    },
    {
      'kind': 'block',
      'type': 'c_motion_sadness_sync'
    },
    {
      'kind': 'block',
      'type': 'c_motion_sadness_async'
    },
    {
      'kind': 'block',
      'type': 'c_motion_shame_sync'
    },
    {
      'kind': 'block',
      'type': 'c_motion_shame_async'
    },
    {
      'kind': 'block',
      'type': 'c_motion_anxiety_sync'
    },
    {
      'kind': 'block',
      'type': 'c_motion_anxiety_async'
    },
    {
      'kind': 'block',
      'type': 'c_motion_fear_sync'
    },
    {
      'kind': 'block',
      'type': 'c_motion_fear_async'
    },
    {
      'kind': 'block',
      'type': 'c_motion_ashamed_sync'
    },
    {
      'kind': 'block',
      'type': 'c_motion_ashamed_async'
    },
    {
      'kind': 'block',
      'type': 'c_motion_shocked_sync'
    },
    {
      'kind': 'block',
      'type': 'c_motion_shocked_async'
    },
    {
      'kind': 'block',
      'type': 'c_motion_bored_sync'
    },
    {
      'kind': 'block',
      'type': 'c_motion_bored_async'
    },
    {
      'kind': 'block',
      'type': 'c_motion_tired_sync'
    },
    {
      'kind': 'block',
      'type': 'c_motion_tired_async'
    },
    {
      'kind': 'block',
      'type': 'c_motion_confused_sync'
    },
    {
      'kind': 'block',
      'type': 'c_motion_confused_async'
    },
    {
      'kind': 'block',
      'type': 'c_motion_embarrassed_sync'
    },
    {
      'kind': 'block',
      'type': 'c_motion_embarrassed_async'
    },
    {
      'kind': 'block',
      'type': 'c_motion_jealous_sync'
    },
    {
      'kind': 'block',
      'type': 'c_motion_jealous_async'
    },
    {
      'kind': 'block',
      'type': 'c_motion_suspicious_sync'
    },
    {
      'kind': 'block',
      'type': 'c_motion_suspicious_async'
    },
    {
      'kind': 'block',
      'type': 'c_motion_mad_sync'
    },
    {
      'kind': 'block',
      'type': 'c_motion_mad_async'
    },
    {
      'kind': 'block',
      'type': 'c_motion_unsatisfied_sync'
    },
    {
      'kind': 'block',
      'type': 'c_motion_unsatisfied_async'
    },
    {
      'kind': 'block',
      'type': 'c_motion_annoyed_sync'
    },
    {
      'kind': 'block',
      'type': 'c_motion_annoyed_async'
    },
    {
      'kind': 'block',
      'type': 'c_motion_conversationAnalyzing_sync'
    },
    {
      'kind': 'block',
      'type': 'c_motion_conversationAnalyzing_async'
    },
    {
      'kind': 'block',
      'type': 'c_motion_conversationFailed_sync'
    },
    {
      'kind': 'block',
      'type': 'c_motion_conversationFailed_async'
    },
    {
      'kind': 'block',
      'type': 'c_motion_lookAround_sync'
    },
    {
      'kind': 'block',
      'type': 'c_motion_lookAround_async'
    },
    {
      'kind': 'block',
      'type': 'c_motion_shakeHeadset_sync'
    },
    {
      'kind': 'block',
      'type': 'c_motion_shakeHeadset_async'
    },
    // {
    //   'kind': 'block',
    //   'type': 'c_motion_shakeHeadsetWithRepeat_sync'
    // },
    // {
    //   'kind': 'block',
    //   'type': 'c_motion_shakeHeadsetWithRepeat_async'
    // },
    {
      'kind': 'block',
      'type': 'c_motion_notice_sync'
    },
    {
      'kind': 'block',
      'type': 'c_motion_notice_async'
    },
    {
      'kind': 'block',
      'type': 'c_motion_voiceMailDeliveryEnd_sync'
    },
    {
      'kind': 'block',
      'type': 'c_motion_voiceMailDeliveryEnd_async'
    },
    {
      'kind': 'block',
      'type': 'c_motion_awakeword_sync'
    },     
    {
      'kind': 'block',
      'type': 'c_motion_awakeword_async'
    },      
    {
      'kind': 'block',
      'type': 'c_motion_upDown_sync'
    },     
    {
      'kind': 'block',
      'type': 'c_motion_upDown_async'
    },            
    // {
    //   'kind': 'block',
    //   'type': 'c_motion_doNotKnow_sync'
    // },
    // {
    //   'kind': 'block',
    //   'type': 'c_motion_doNotKnow_async'
    // },
    // {
    //   'kind': 'block',
    //   'type': 'c_motion_exclamation_sync'
    // },
    // {
    //   'kind': 'block',
    //   'type': 'c_motion_exclamation_async'
    // },
    // {
    //   'kind': 'block',
    //   'type': 'c_motion_dance_sync'
    // },
    // {
    //   'kind': 'block',
    //   'type': 'c_motion_dance_async'
    // },
    // {
    //   'kind': 'block',
    //   'type': 'c_motion_danceEdmCes_sync'
    // },
    // {
    //   'kind': 'block',
    //   'type': 'c_motion_danceEdmCes_async'
    // },    
    // {
    //   'kind': 'block',
    //   'type': 'c_motion_danceKbis_sync'
    // },
    // {
    //   'kind': 'block',
    //   'type': 'c_motion_danceKbis_async'
    // },    
    {
      'kind': 'block',
      'type': 'c_motion_stopMotion'
    }                  
  ],
}