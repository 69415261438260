import Blockly from 'blockly/core';
import '@blockly/field-angle';
import '@blockly/field-slider';

// block type naming rule : {toolbox_name}_{block_description}

const printJson = {
	"implicitAlign0": "CENTRE",
	"message0": 'print %1',
	"args0": [
		{
			"type": "input_value",
			"name": "TEXT",
		}
	],
	"previousStatement": null,
	"nextStatement": null,
	"style": "developer_blocks",
}

Blockly.Blocks['c_developer_print'] = {
	init: function() {
		this.jsonInit(printJson);
	}
}

const rootCheckerJson = {
	"message0": "rootChecker",
	"inputsInline": false,
	"previousStatement": null,
	"nextStatement": null,
	"style": "developer_blocks",
}

Blockly.Blocks['c_developer_rootChecker'] = {
	init: function() {
		this.jsonInit(rootCheckerJson);
	},

	checkRootBlock: function() {
		let root = this.getRootBlock();
		return root.type;
	},

	onchange: function(e) {		
		if (this.workspace.isDragging()) return;
		if (e.type !== Blockly.Events.BLOCK_MOVE) return;
		
		if (this.checkRootBlock() !== 'c_program_start') {			
			this.setEnabled(false);
			this.setWarningText('Need to be attached to the start block tree.');
		} else {
			this.setEnabled(true);
			this.setWarningText('');
		}		
	}
}

// Extensions and Mutators