import { javascriptGenerator } from "blockly/javascript";

javascriptGenerator.forBlock['c_movement_rotateHeadsetWithSpeed_sync'] = function(block, generator) {	
	const targetAngle = block.getFieldValue('ANGLE');			
	// const seconds = block.getFieldValue('SECOND');
	const selectedSpeed = block.getFieldValue('SPEED');
	// const code = `rotateHeadset(${targetAngle}, ${seconds});\nheadsetMotionFinishChecker();\n`;
	const code = `rotateHeadsetAsync(${targetAngle}, '${selectedSpeed}');\nheadsetMotionFinishChecker();\n`;
	return code;
};

javascriptGenerator.forBlock['c_movement_rotateHeadsetWithSpeed_async'] = function(block, generator) {	
	const targetAngle = block.getFieldValue('ANGLE');			
	// const seconds = block.getFieldValue('SECOND');
	const selectedSpeed = block.getFieldValue('SPEED');
	// const code = `rotateHeadset(${targetAngle}, ${seconds});\n`;
	const code = `rotateHeadsetAsync(${targetAngle}, '${selectedSpeed}');\n`;
	return code;
};

javascriptGenerator.forBlock['c_movement_stopHeadset'] = function(block, generator) {	
	const code = `stopHeadset();\n`;
	return code;
};

javascriptGenerator.forBlock['c_movement_moveBothHipWithSpeed_sync'] = function(block, generator) {		
	const targetAngleL = block.getFieldValue('LEFT');			
	const targetAngleR = block.getFieldValue('RIGHT');		
	const selectedSpeed = block.getFieldValue('SPEED');
	// const code = `moveBothHip(${targetAngleL}, ${targetAngleR}, '${selectedSpeed}');\nhipMotionFinishChecker();\n`;
	const code = `moveBothHipAsync(${targetAngleL}, ${targetAngleR}, '${selectedSpeed}');\nhipMotionFinishChecker();\n`;
	return code;
};

javascriptGenerator.forBlock['c_movement_moveBothHipWithSpeed_async'] = function(block, generator) {		
	const targetAngleL = block.getFieldValue('LEFT');			
	const targetAngleR = block.getFieldValue('RIGHT');		
	const selectedSpeed = block.getFieldValue('SPEED');
	// const code = `moveBothHip(${targetAngleL}, ${targetAngleR}, '${selectedSpeed}');\n`;
	const code = `moveBothHipAsync(${targetAngleL}, ${targetAngleR}, '${selectedSpeed}');\n`;
	return code;
};

javascriptGenerator.forBlock['c_movement_moveOneHipWithSpeed_sync'] = function(block, generator) {	
	const selectedHip = block.getFieldValue('HIP');
	const targetAngle = block.getFieldValue('ANGLE');	
	const selectedSpeed = block.getFieldValue('SPEED');
	// const code = `moveOneHip('${selectedHip}', ${targetAngle}, '${selectedSpeed}');\nhipMotionFinishChecker();\n`;
	const code = `moveOneHipAsync('${selectedHip}', ${targetAngle}, '${selectedSpeed}');\nhipMotionFinishChecker();\n`;
	return code;
};

javascriptGenerator.forBlock['c_movement_moveOneHipWithSpeed_async'] = function(block, generator) {	
	const selectedHip = block.getFieldValue('HIP');
	const targetAngle = block.getFieldValue('ANGLE');	
	const selectedSpeed = block.getFieldValue('SPEED');
	// const code = `moveOneHip('${selectedHip}', ${targetAngle}, '${selectedSpeed}');\n`;
	const code = `moveOneHipAsync('${selectedHip}', ${targetAngle}, '${selectedSpeed}');\n`;
	return code;
};

javascriptGenerator.forBlock['c_movement_tiltBodyWithSpeed_sync'] = function(block, generator) {		
	const direction = block.getFieldValue('DIRECTION');
	const targetAngle = block.getFieldValue('ANGLE');	
	// const selectedSpeed = block.getFieldValue('SPEED');
	// const code = `tiltBody('${direction}', ${targetAngle}, '${selectedSpeed}');\nhipMotionFinishChecker();\n`;
	const code = `tiltBodyAsync('${direction}', ${targetAngle});\nhipMotionFinishChecker();\n`;
	return code;
};

javascriptGenerator.forBlock['c_movement_tiltBodyWithSpeed_async'] = function(block, generator) {		
	const direction = block.getFieldValue('DIRECTION');
	const targetAngle = block.getFieldValue('ANGLE');	
	// const selectedSpeed = block.getFieldValue('SPEED');
	// const code = `tiltBody('${direction}', ${targetAngle}, '${selectedSpeed}');\n`;
	const code = `tiltBodyAsync('${direction}', ${targetAngle});\n`;
	return code;
};

javascriptGenerator.forBlock['c_movement_stopHip'] = function(block, generator) {	
	const code = `stopHip();\n`;
	return code;
};

javascriptGenerator.forBlock['c_movement_spinDegreesWithSpeed_sync'] = function(block, generator) {	
	const angle = block.getFieldValue('ANGLE');
	const direction = block.getFieldValue('DIRECTION');
	const selectedSpeed = block.getFieldValue('SPEED');
	// const code = `spinDegrees('${direction}', ${angle}, '${selectedSpeed}');\nwheelMoveFinishChecker();\n`;
	const code = `spinDegreesAsync('${direction}', ${angle}, '${selectedSpeed}');\nwheelMoveFinishChecker();\n`;
	return code;
};

javascriptGenerator.forBlock['c_movement_spinDegreesWithSpeed_async'] = function(block, generator) {	
	const angle = block.getFieldValue('ANGLE');
	const direction = block.getFieldValue('DIRECTION');
	const selectedSpeed = block.getFieldValue('SPEED');
	// const code = `spinDegrees('${direction}', ${angle}, '${selectedSpeed}');\n`;
	const code = `spinDegreesAsync('${direction}', ${angle}, '${selectedSpeed}');\n`;
	return code;
};

javascriptGenerator.forBlock['c_movement_moveDistanceWithSpeed_sync'] = function(block, generator) {	
	const direction = block.getFieldValue('DIRECTION');
	const distance = block.getFieldValue('DISTANCE');	
	const speed = Number(block.getFieldValue('SPEED'));	
	// const code = `moveDistance('${direction}', ${distance}, ${speed});\nwheelMoveFinishChecker();\n`;
	const code = `moveDistanceAsync('${direction}', ${distance}, ${speed});\nwheelMoveFinishChecker();\n`;
	return code;
};

javascriptGenerator.forBlock['c_movement_moveDistanceWithSpeed_async'] = function(block, generator) {	
	const direction = block.getFieldValue('DIRECTION');
	const distance = block.getFieldValue('DISTANCE');	
	const speed = Number(block.getFieldValue('SPEED'));	
	// const code = `moveDistance('${direction}', ${distance}, ${speed});\n`;
	const code = `moveDistanceAsync('${direction}', ${distance}, ${speed});\n`;
	return code;
};

javascriptGenerator.forBlock['c_movement_moveAlongTurningRadius_sync'] = function(block, generator) {	
	const direction = block.getFieldValue('DIRECTION');
	const angle = block.getFieldValue('ANGLE');
	const radius = block.getFieldValue('RADIUS');	
	const selectedSpeed = Number(block.getFieldValue('SPEED'));
	// const code = `moveAlongTurningRadius('${direction}', ${angle}, ${radius}, ${selectedSpeed});\nwheelMoveFinishChecker();\n`;
	const code = `moveAlongTurningRadiusAsync('${direction}', ${angle}, ${radius}, ${selectedSpeed});\nwheelMoveFinishChecker();\n`;
	return code;
};

javascriptGenerator.forBlock['c_movement_moveAlongTurningRadius_async'] = function(block, generator) {	
	const direction = block.getFieldValue('DIRECTION');
	const angle = block.getFieldValue('ANGLE');
	const radius = block.getFieldValue('RADIUS');	
	const selectedSpeed = Number(block.getFieldValue('SPEED'));
	// const code = `moveAlongTurningRadius('${direction}', ${angle}, ${radius}, ${selectedSpeed});\n`;
	const code = `moveAlongTurningRadiusAsync('${direction}', ${angle}, ${radius}, ${selectedSpeed});\n`;
	return code;
};

javascriptGenerator.forBlock['c_movement_stopWheel'] = function(block, generator) {	
	const code = `stopWheelAsync();\n`;
	return code;
};

javascriptGenerator.forBlock['c_movement_stopAllMovement'] = function(block, generator) {	
	const code = `stopAllMovementAsync();\n`;
	return code;
};

javascriptGenerator.forBlock['c_movement_initPose'] = function(block, generator) {	
	const code = `initPoseAsync();\ninitPoseFinishChecker();\n`;
	return code;
};