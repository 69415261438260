export const toolboxMovement = {
  'kind': 'category',
  'name': 'Movement',
  'categorystyle': 'movement_category',
  'contents': [
    {
      "kind": "label",
      "text": "HEADSET"
    },
    {
      'kind': 'block',
      'type': 'c_movement_rotateHeadsetWithSpeed_sync'
    },
    {
      'kind': 'block',
      'type': 'c_movement_rotateHeadsetWithSpeed_async'
    },
    {
      'kind': 'block',
      'type': 'c_movement_stopHeadset'
    },
    {
      "kind": "label",
      "text": "HIP"
    },
    {
      'kind': 'block',
      'type': 'c_movement_moveBothHipWithSpeed_sync'
    },
    {
      'kind': 'block',
      'type': 'c_movement_moveBothHipWithSpeed_async'
    },
    {
      'kind': 'block',
      'type': 'c_movement_moveOneHipWithSpeed_sync'
    },
    {
      'kind': 'block',
      'type': 'c_movement_moveOneHipWithSpeed_async'
    },
    {
      'kind': 'block',
      'type': 'c_movement_tiltBodyWithSpeed_sync'
    },
    {
      'kind': 'block',
      'type': 'c_movement_tiltBodyWithSpeed_async'
    },
    {
      'kind': 'block',
      'type': 'c_movement_stopHip'
    },
    {
      "kind": "label",
      "text": "WHEEL"
    },
    {
      'kind': 'block',
      'type': 'c_movement_spinDegreesWithSpeed_sync'
    },
    {
      'kind': 'block',
      'type': 'c_movement_spinDegreesWithSpeed_async'
    },
    {
      'kind': 'block',
      'type': 'c_movement_moveDistanceWithSpeed_sync'
    },
    {
      'kind': 'block',
      'type': 'c_movement_moveDistanceWithSpeed_async'
    },
    {
      'kind': 'block',
      'type': 'c_movement_moveAlongTurningRadius_sync'
    },    
    {
      'kind': 'block',
      'type': 'c_movement_moveAlongTurningRadius_async'
    },    
    {
      'kind': 'block',
      'type': 'c_movement_stopWheel'
    },
    {
      "kind": "label",
      "text": "STOP MOVEMENT / INITIALIZE POSE"
    },     
    {
      'kind': 'block',
      'type': 'c_movement_stopAllMovement'
    },
    {
      "kind": "block",
      "type": "c_movement_initPose"
    },
  ]
}