import { javascriptGenerator } from "blockly/javascript";

const startMotionSyncFunction = function(block, generator) {
	const motionId = Number(block.getFieldValue('MOTION'));	
	// const code = `startMotion(${motionId});\nmotionFinishChecker();\n`;
	let code = `initPoseAsync();\ninitPoseFinishChecker();\n`;
	code += `startMotionAsync(${motionId});\nmotionFinishChecker();\n`;
	return code;
};

javascriptGenerator.forBlock['c_motion_awe_sync'] = startMotionSyncFunction;
javascriptGenerator.forBlock['c_motion_exciting_sync'] = startMotionSyncFunction;
javascriptGenerator.forBlock['c_motion_surprise_sync'] = startMotionSyncFunction;
javascriptGenerator.forBlock['c_motion_anticipation_sync'] = startMotionSyncFunction;
javascriptGenerator.forBlock['c_motion_confident_sync'] = startMotionSyncFunction;
javascriptGenerator.forBlock['c_motion_love_sync'] = startMotionSyncFunction;
javascriptGenerator.forBlock['c_motion_delight_sync'] = startMotionSyncFunction;
javascriptGenerator.forBlock['c_motion_joy_sync'] = startMotionSyncFunction;
javascriptGenerator.forBlock['c_motion_curiosity_sync'] = startMotionSyncFunction;
javascriptGenerator.forBlock['c_motion_relieved_sync'] = startMotionSyncFunction;
javascriptGenerator.forBlock['c_motion_satisfied_sync'] = startMotionSyncFunction;
javascriptGenerator.forBlock['c_motion_touched_sync'] = startMotionSyncFunction;
javascriptGenerator.forBlock['c_motion_agree_sync'] = startMotionSyncFunction;
javascriptGenerator.forBlock['c_motion_shyness_sync'] = startMotionSyncFunction;
javascriptGenerator.forBlock['c_motion_proud_sync'] = startMotionSyncFunction;
javascriptGenerator.forBlock['c_motion_interested_sync'] = startMotionSyncFunction;
javascriptGenerator.forBlock['c_motion_playful_sync'] = startMotionSyncFunction;
javascriptGenerator.forBlock['c_motion_tremble_sync'] = startMotionSyncFunction;
javascriptGenerator.forBlock['c_motion_thrilled_sync'] = startMotionSyncFunction;
javascriptGenerator.forBlock['c_motion_thinking_sync'] = startMotionSyncFunction;
javascriptGenerator.forBlock['c_motion_dizzy_sync'] = startMotionSyncFunction;
javascriptGenerator.forBlock['c_motion_sadness_sync'] = startMotionSyncFunction;
javascriptGenerator.forBlock['c_motion_shame_sync'] = startMotionSyncFunction;
javascriptGenerator.forBlock['c_motion_anxiety_sync'] = startMotionSyncFunction;
javascriptGenerator.forBlock['c_motion_fear_sync'] = startMotionSyncFunction;
javascriptGenerator.forBlock['c_motion_ashamed_sync'] = startMotionSyncFunction;
javascriptGenerator.forBlock['c_motion_shocked_sync'] = startMotionSyncFunction;
javascriptGenerator.forBlock['c_motion_bored_sync'] = startMotionSyncFunction;
javascriptGenerator.forBlock['c_motion_tired_sync'] = startMotionSyncFunction;
javascriptGenerator.forBlock['c_motion_confused_sync'] = startMotionSyncFunction;
javascriptGenerator.forBlock['c_motion_embarrassed_sync'] = startMotionSyncFunction;
javascriptGenerator.forBlock['c_motion_jealous_sync'] = startMotionSyncFunction;
javascriptGenerator.forBlock['c_motion_suspicious_sync'] = startMotionSyncFunction;
javascriptGenerator.forBlock['c_motion_mad_sync'] = startMotionSyncFunction;
javascriptGenerator.forBlock['c_motion_unsatisfied_sync'] = startMotionSyncFunction;
javascriptGenerator.forBlock['c_motion_annoyed_sync'] = startMotionSyncFunction;
javascriptGenerator.forBlock['c_motion_conversationAnalyzing_sync'] = startMotionSyncFunction;
javascriptGenerator.forBlock['c_motion_conversationFailed_sync'] = startMotionSyncFunction;
javascriptGenerator.forBlock['c_motion_lookAround_sync'] = startMotionSyncFunction;
javascriptGenerator.forBlock['c_motion_shakeHeadset_sync'] = startMotionSyncFunction;
javascriptGenerator.forBlock['c_motion_notice_sync'] = startMotionSyncFunction;
javascriptGenerator.forBlock['c_motion_voiceMailDeliveryEnd_sync'] = startMotionSyncFunction;
javascriptGenerator.forBlock['c_motion_awakeword_sync'] = startMotionSyncFunction;
javascriptGenerator.forBlock['c_motion_upDown_sync'] = startMotionSyncFunction;
// javascriptGenerator.forBlock['c_motion_doNotKnow_sync'] = startMotionSyncFunction;
// javascriptGenerator.forBlock['c_motion_exclamation_sync'] = startMotionSyncFunction;
// javascriptGenerator.forBlock['c_motion_withAcceleration_sync'] = startMotionSyncFunction;
// javascriptGenerator.forBlock['c_motion_withDeceleration_sync'] = startMotionSyncFunction;
// javascriptGenerator.forBlock['c_motion_dance_sync'] = startMotionSyncFunction;
// javascriptGenerator.forBlock['c_motion_danceEdmCes_sync'] = startMotionSyncFunction;
// javascriptGenerator.forBlock['c_motion_danceKbis_sync'] = startMotionSyncFunction;

const startMotionAsyncFunction = function(block, generator) {
	const motionId = Number(block.getFieldValue('MOTION'));	
	// const code = `startMotion(${motionId});\n`;
	let code = `initPoseAsync();\ninitPoseFinishChecker();\n`;
	code += `startMotionAsync(${motionId});\n`;
	return code;
};

javascriptGenerator.forBlock['c_motion_awe_async'] = startMotionAsyncFunction;
javascriptGenerator.forBlock['c_motion_exciting_async'] = startMotionAsyncFunction;
javascriptGenerator.forBlock['c_motion_surprise_async'] = startMotionAsyncFunction;
javascriptGenerator.forBlock['c_motion_anticipation_async'] = startMotionAsyncFunction;
javascriptGenerator.forBlock['c_motion_confident_async'] = startMotionAsyncFunction;
javascriptGenerator.forBlock['c_motion_love_async'] = startMotionAsyncFunction;
javascriptGenerator.forBlock['c_motion_delight_async'] = startMotionAsyncFunction;
javascriptGenerator.forBlock['c_motion_joy_async'] = startMotionAsyncFunction;
javascriptGenerator.forBlock['c_motion_curiosity_async'] = startMotionAsyncFunction;
javascriptGenerator.forBlock['c_motion_relieved_async'] = startMotionAsyncFunction;
javascriptGenerator.forBlock['c_motion_satisfied_async'] = startMotionAsyncFunction;
javascriptGenerator.forBlock['c_motion_touched_async'] = startMotionAsyncFunction;
javascriptGenerator.forBlock['c_motion_agree_async'] = startMotionAsyncFunction;
javascriptGenerator.forBlock['c_motion_shyness_async'] = startMotionAsyncFunction;
javascriptGenerator.forBlock['c_motion_proud_async'] = startMotionAsyncFunction;
javascriptGenerator.forBlock['c_motion_interested_async'] = startMotionAsyncFunction;
javascriptGenerator.forBlock['c_motion_playful_async'] = startMotionAsyncFunction;
javascriptGenerator.forBlock['c_motion_tremble_async'] = startMotionAsyncFunction;
javascriptGenerator.forBlock['c_motion_thrilled_async'] = startMotionAsyncFunction;
javascriptGenerator.forBlock['c_motion_thinking_async'] = startMotionAsyncFunction;
javascriptGenerator.forBlock['c_motion_dizzy_async'] = startMotionAsyncFunction;
javascriptGenerator.forBlock['c_motion_sadness_async'] = startMotionAsyncFunction;
javascriptGenerator.forBlock['c_motion_shame_async'] = startMotionAsyncFunction;
javascriptGenerator.forBlock['c_motion_anxiety_async'] = startMotionAsyncFunction;
javascriptGenerator.forBlock['c_motion_fear_async'] = startMotionAsyncFunction;
javascriptGenerator.forBlock['c_motion_ashamed_async'] = startMotionAsyncFunction;
javascriptGenerator.forBlock['c_motion_shocked_async'] = startMotionAsyncFunction;
javascriptGenerator.forBlock['c_motion_bored_async'] = startMotionAsyncFunction;
javascriptGenerator.forBlock['c_motion_tired_async'] = startMotionAsyncFunction;
javascriptGenerator.forBlock['c_motion_confused_async'] = startMotionAsyncFunction;
javascriptGenerator.forBlock['c_motion_embarrassed_async'] = startMotionAsyncFunction;
javascriptGenerator.forBlock['c_motion_jealous_async'] = startMotionAsyncFunction;
javascriptGenerator.forBlock['c_motion_suspicious_async'] = startMotionAsyncFunction;
javascriptGenerator.forBlock['c_motion_mad_async'] = startMotionAsyncFunction;
javascriptGenerator.forBlock['c_motion_unsatisfied_async'] = startMotionAsyncFunction;
javascriptGenerator.forBlock['c_motion_annoyed_async'] = startMotionAsyncFunction;
javascriptGenerator.forBlock['c_motion_conversationAnalyzing_async'] = startMotionAsyncFunction;
javascriptGenerator.forBlock['c_motion_conversationFailed_async'] = startMotionAsyncFunction;
javascriptGenerator.forBlock['c_motion_lookAround_async'] = startMotionAsyncFunction;
javascriptGenerator.forBlock['c_motion_shakeHeadset_async'] = startMotionAsyncFunction;
javascriptGenerator.forBlock['c_motion_notice_async'] = startMotionAsyncFunction;
javascriptGenerator.forBlock['c_motion_voiceMailDeliveryEnd_async'] = startMotionAsyncFunction;
javascriptGenerator.forBlock['c_motion_awakeword_async'] = startMotionAsyncFunction;
javascriptGenerator.forBlock['c_motion_upDown_async'] = startMotionAsyncFunction;
// javascriptGenerator.forBlock['c_motion_doNotKnow_async'] = startMotionAsyncFunction;
// javascriptGenerator.forBlock['c_motion_exclamation_async'] = startMotionAsyncFunction;
// javascriptGenerator.forBlock['c_motion_withAcceleration_async'] = startMotionAsyncFunction;
// javascriptGenerator.forBlock['c_motion_withDeceleration_async'] = startMotionAsyncFunction;
// javascriptGenerator.forBlock['c_motion_dance_async'] = startMotionAsyncFunction;
// javascriptGenerator.forBlock['c_motion_danceEdmCes_async'] = startMotionAsyncFunction;
// javascriptGenerator.forBlock['c_motion_danceKbis_async'] = startMotionAsyncFunction;

javascriptGenerator.forBlock['c_motion_stopMotion'] = function(block, generator) {	
	const code = `stopMotion();\n`;
	return code;
};

javascriptGenerator.forBlock['c_motion_shakeHeadsetWithRepeat_sync'] = function(block, generator) {	
	const motionId = 51;	
	const code = `startInPlaceMotionWithRepeatAsync(${motionId});\nmotionFinishChecker();\n`;
	return code;
};

javascriptGenerator.forBlock['c_motion_shakeHeadsetWithRepeat_async'] = function(block, generator) {	
	const motionId = 51;	
	const repeat = Number(block.getFieldValue('REPEAT'));	
	const code = `startInPlaceMotionWithRepeatAsync(${motionId}, ${repeat});\n`;
	return code;
};