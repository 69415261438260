import Blockly from 'blockly/core';
import '@blockly/field-angle';
import '@blockly/field-slider';
import '@blockly/field-dependent-dropdown';

// block type naming rule : {toolbox_name}_{block_description}

const rotateHeadsetWithSpeedJson = {	
	"args0": [		
		{
			"type": "field_angle",
			"name": "ANGLE",
			"min": 0,
			"max": 240,
			"value": 180,
			"precision": 1,
			"clockwise": true,
			"offset": 90,
			"displayMin": 0,
			"displayMax": 280,
			"minorTick": 10,
			"majorTick": 70
		},
		// {
		// 	"type": "field_slider",
		// 	"name": "SECOND",
		// 	"min": 0.1,
		// 	"max": 3,
		// 	"value": 1,
		// 	"precision": 0.1,			
		// }
		{
			'type': 'field_dropdown',
			'name': 'SPEED',
			'options': [			
				['SLOW', 'slow'],
				['NORMAL', 'normal'],
				['FAST', 'fast'],
				['FASTEST', 'fastest'],
			],
		}
	],
	"previousStatement": null,
	"nextStatement": null,
	"style": "movement_blocks",
}

const rotateHeadsetWithSpeedSyncJson = JSON.parse(JSON.stringify(rotateHeadsetWithSpeedJson));
// rotateHeadsetWithSpeedSyncJson.message0 = "rotate headset to %1 in %2 sec until end";
rotateHeadsetWithSpeedSyncJson.message0 = "rotate headset to %1 %2 until end";
Blockly.Blocks['c_movement_rotateHeadsetWithSpeed_sync'] = {
	init: function() {
		this.jsonInit(rotateHeadsetWithSpeedSyncJson);
	}
}

const rotateHeadsetWithSpeedAsyncJson = JSON.parse(JSON.stringify(rotateHeadsetWithSpeedJson));
// rotateHeadsetWithSpeedAsyncJson.message0 = "rotate headset to %1 in %2 sec";
rotateHeadsetWithSpeedAsyncJson.message0 = "rotate headset to %1 %2";
Blockly.Blocks['c_movement_rotateHeadsetWithSpeed_async'] = {
	init: function() {
		this.jsonInit(rotateHeadsetWithSpeedAsyncJson);
	}
}

const stopHeadsetJson = {
	"message0": "stop headset motion",
	"inputsInline": false,
	"previousStatement": null,
	"nextStatement": null,
	"style": "movement_blocks",
}

Blockly.Blocks['c_movement_stopHeadset'] = {
	init: function() {
		this.jsonInit(stopHeadsetJson);
	}
}

const moveBothHipWithSpeedJson = {	
	"args0": [
		{
			"type": "field_angle",
			"name": "LEFT",
			"min": 0,
			"max": 22,
			"value": 10,
			"precision": 1,
			"clockwise": true,
			"offset": 90,
			"displayMin": 0,
			"displayMax": 28,
			"minorTick": 1,
			"majorTick": 7
		},
		{
			"type": "field_angle",
			"name": "RIGHT",
			"min": 0,
			"max": 22,
			"value": 10,
			"precision": 1,
			"clockwise": true,
			"offset": 90,
			"displayMin": 0,
			"displayMax": 28,
			"minorTick": 1,
			"majorTick": 7
		},
		{
			'type': 'field_dropdown',
			'name': 'SPEED',
			'options': [			
				['SLOW', 'slow'],
				['NORMAL', 'normal'],
				['FAST', 'fast']
			],
		}
	],
	"previousStatement": null,
	"nextStatement": null,
	"style": "movement_blocks",
}

const moveBothHipWithSpeedSyncJson = JSON.parse(JSON.stringify(moveBothHipWithSpeedJson));
moveBothHipWithSpeedSyncJson.message0 = "move left hip to %1 & right hip to %2 %3 until end";
Blockly.Blocks['c_movement_moveBothHipWithSpeed_sync'] = {
	init: function() {
		this.jsonInit(moveBothHipWithSpeedSyncJson);
	}
}

const moveBothHipWithSpeedAsyncJson = JSON.parse(JSON.stringify(moveBothHipWithSpeedJson));
moveBothHipWithSpeedAsyncJson.message0 = "move left hip to %1 & right hip to %2 %3";
Blockly.Blocks['c_movement_moveBothHipWithSpeed_async'] = {
	init: function() {
		this.jsonInit(moveBothHipWithSpeedAsyncJson);
	}
}

const moveOneHipWithSpeedJson = {
	"args0": [
		{
			'type': 'field_dropdown',
			'name': 'HIP',
			'options': [			
				['LEFT', 'left'],
				['RIGHT', 'right']
			],
		},
		{
			"type": "field_angle",
			"name": "ANGLE",
			"min": 0,
			"max": 22,
			"value": 10,
			"precision": 1,
			"clockwise": true,
			"offset": 90,
			"displayMin": 0,
			"displayMax": 28,
			"minorTick": 1,
			"majorTick": 7
		},
		{
			'type': 'field_dropdown',
			'name': 'SPEED',
			'options': [			
				['SLOW', 'slow'],
				['NORMAL', 'normal'],
				['FAST', 'fast']
			],
		}
	],
	"previousStatement": null,
	"nextStatement": null,
	"style": "movement_blocks",
}

const moveOneHipWithSpeedSyncJson = JSON.parse(JSON.stringify(moveOneHipWithSpeedJson));
moveOneHipWithSpeedSyncJson.message0 = "move %1 hip to %2 %3 until end";
Blockly.Blocks['c_movement_moveOneHipWithSpeed_sync'] = {
	init: function() {
		this.jsonInit(moveOneHipWithSpeedSyncJson);
	}
}

const moveOneHipWithSpeedAsyncJson = JSON.parse(JSON.stringify(moveOneHipWithSpeedJson));
moveOneHipWithSpeedAsyncJson.message0 = "move %1 hip to %2 %3";
Blockly.Blocks['c_movement_moveOneHipWithSpeed_async'] = {
	init: function() {
		this.jsonInit(moveOneHipWithSpeedAsyncJson);
	}
}

const tiltBodyWithSpeedJson = {
	"args0": [
		{
			'type': 'field_dropdown',
			'name': 'DIRECTION',
			'options': [			
				['LEFT', 'left'],
				['RIGHT', 'right']
			],
		},
		{
			"type": "field_angle",
			"name": "ANGLE",
			"min": 0,
			"max": 17,
			"value": 10,
			"precision": 1,
			"clockwise": true,
			"offset": 90,
			"displayMin": 0,
			"displayMax": 24,
			"minorTick": 1,
			"majorTick": 6
		},
		// {
		// 	'type': 'field_dropdown',
		// 	'name': 'SPEED',
		// 	'options': [			
		// 		['SLOW', 'slow'],
		// 		['NORMAL', 'normal'],
		// 		['FAST', 'fast']
		// 	],
		// }
	],
	"previousStatement": null,
	"nextStatement": null,
	"style": "movement_blocks",
}

const tiltBodyWithSpeedSyncJson = JSON.parse(JSON.stringify(tiltBodyWithSpeedJson));
// tiltBodyWithSpeedSyncJson.message0 = "tilt body to the %1 %2 %3 until end";
tiltBodyWithSpeedSyncJson.message0 = "tilt body to the %1 %2 until end";
Blockly.Blocks['c_movement_tiltBodyWithSpeed_sync'] = {
	init: function() {
		this.jsonInit(tiltBodyWithSpeedSyncJson);
	}
}

const tiltBodyWithSpeedAsyncJson = JSON.parse(JSON.stringify(tiltBodyWithSpeedJson));
// tiltBodyWithSpeedAsyncJson.message0 = "tilt body to the %1 %2 %3";
tiltBodyWithSpeedAsyncJson.message0 = "tilt body to the %1 %2";
Blockly.Blocks['c_movement_tiltBodyWithSpeed_async'] = {
	init: function() {
		this.jsonInit(tiltBodyWithSpeedAsyncJson);
	}
}

const stopHipJson = {
	"message0": "stop hip(body) motion",
	"inputsInline": false,
	"previousStatement": null,
	"nextStatement": null,
	"style": "movement_blocks",
}

Blockly.Blocks['c_movement_stopHip'] = {
	init: function() {
		this.jsonInit(stopHipJson);
	}
}

const spinDegreesWithSpeedJson = {
	"args0": [
		{
			"type": "field_angle",
			"name": "ANGLE",
			"precision": 1,
			"clockwise": true,
			"offset": 90,
		},
		{
			'type': 'field_dropdown',
			'name': 'DIRECTION',
			'options': [			
				['LEFT', 'left'],
				['RIGHT', 'right']
			],
		},
		{
			'type': 'field_dropdown',
			'name': 'SPEED',
			'options': [			
				['SLOW', 'slow'],
				['NORMAL', 'normal'],
				['FAST', 'fast'],
				['FASTEST', 'fastest']
			],
		}
	],
	"previousStatement": null,
	"nextStatement": null,
	"style": "movement_blocks",
}

const spinDegreesWithSpeedSyncJson = JSON.parse(JSON.stringify(spinDegreesWithSpeedJson));
spinDegreesWithSpeedSyncJson.message0 = "spin %1 degrees to %2 %3 until end";
Blockly.Blocks['c_movement_spinDegreesWithSpeed_sync'] = {
	init: function() {
		this.jsonInit(spinDegreesWithSpeedSyncJson);
	}
}

const spinDegreesWithSpeedAsyncJson = JSON.parse(JSON.stringify(spinDegreesWithSpeedJson));
spinDegreesWithSpeedAsyncJson.message0 = "spin %1 degrees to %2 %3";
Blockly.Blocks['c_movement_spinDegreesWithSpeed_async'] = {
	init: function() {
		this.jsonInit(spinDegreesWithSpeedAsyncJson);
	}
}

const moveDistanceWithSpeedJson = {
	"args0": [
		{
			'type': 'field_dropdown',
			'name': 'DIRECTION',
			'options': [			
				['FORWARD', 'forward'],
				['BACKWARD', 'backward']
			],
		},
		{
			"type": "field_slider",
			"name": "DISTANCE",
			"min": 0,
			"max": 2,
			"value": 1,
			"precision": 0.1
		},
		{	
			'type': 'field_dependent_dropdown',
			'name': 'SPEED',
			'parentName': 'DIRECTION',
			'optionMapping': {
				'forward': [
					['SLOW', '0'],
					['NORMAL', '1'],
					['FAST', '2'],
					// ['FASTEST', '3']
				],
				'backward': [
					['SLOW', '0'],
					['NORMAL', '1'],
				]
			}	
		}
	],
	"previousStatement": null,
	"nextStatement": null,
	"style": "movement_blocks",
}

const moveDistanceWithSpeedSyncJson = JSON.parse(JSON.stringify(moveDistanceWithSpeedJson));
moveDistanceWithSpeedSyncJson.message0 = "move %1 %2 meters %3 until end";
Blockly.Blocks['c_movement_moveDistanceWithSpeed_sync'] = {
	init: function() {
		this.jsonInit(moveDistanceWithSpeedSyncJson);
	}
}

const moveDistanceWithSpeedAsyncJson = JSON.parse(JSON.stringify(moveDistanceWithSpeedJson));
moveDistanceWithSpeedAsyncJson.message0 = "move %1 %2 meters %3";
Blockly.Blocks['c_movement_moveDistanceWithSpeed_async'] = {
	init: function() {
		this.jsonInit(moveDistanceWithSpeedAsyncJson);
	}
}

const moveAlongTurningRadiusJson = {	
	"args0": [
		{
			'type': 'field_dropdown',
			'name': 'DIRECTION',
			'options': [			
				['CLOCKWISE', 'clockwise'],
				['COUNTERCLOCKWISE', 'counterclockwise']
			],
		},
		{
			"type": "field_angle",
			"name": "ANGLE",
			"precision": 1,
			"clockwise": true,
			"offset": 90,
		},
		{
			"type": "field_slider",
			"name": "RADIUS",
			"min": 0.3,
			"max": 1.0,
			"value": 0.3,
			"precision": 0.1
		},
		{
			'type': 'field_dropdown',
			'name': 'SPEED',
			'options': [			
				['SLOW', '0'],
				['NORMAL', '1'],
				['FAST', '2'],
			],
		}
	],
	"previousStatement": null,
	"nextStatement": null,
	"style": "movement_blocks",
}
const moveAlongTurningRadiusSyncJson = JSON.parse(JSON.stringify(moveAlongTurningRadiusJson));
moveAlongTurningRadiusSyncJson.message0 = "move %1 %2 along turning radius %3 meter %4 until end";
Blockly.Blocks['c_movement_moveAlongTurningRadius_sync'] = {
	init: function() {
		this.jsonInit(moveAlongTurningRadiusSyncJson);
	}
}

const moveAlongTurningRadiusAsyncJson = JSON.parse(JSON.stringify(moveAlongTurningRadiusJson));
moveAlongTurningRadiusAsyncJson.message0 = "move %1 %2 along turning radius %3 meter %4";
Blockly.Blocks['c_movement_moveAlongTurningRadius_async'] = {
	init: function() {
		this.jsonInit(moveAlongTurningRadiusAsyncJson);
	}
}

const stopWheelJson = {
	"message0": "stop wheel moving",
	"inputsInline": false,
	"previousStatement": null,
	"nextStatement": null,
	"style": "movement_blocks",
}

Blockly.Blocks['c_movement_stopWheel'] = {
	init: function() {
		this.jsonInit(stopWheelJson);
	}
}

const stopAllMovementJson = {
	"message0": "stop all movement",
	"inputsInline": false,
	"previousStatement": null,
	"nextStatement": null,
	"style": "movement_blocks",
}

Blockly.Blocks['c_movement_stopAllMovement'] = {
	init: function() {
		this.jsonInit(stopAllMovementJson);
	}
}

const initPoseJson = {
	"message0": "set robot to initial pose",
	"inputsInline": false,
	"previousStatement": null,
	"nextStatement": null,
	"style": "movement_blocks",
}

Blockly.Blocks['c_movement_initPose'] = {
	init: function() {
		this.jsonInit(initPoseJson);
	}
}

// Extensions and Mutators