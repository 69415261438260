export const playEffectSoundList = [
	// "START",
	"POWER_OFF",    // index 2
	"WAKEWORD_1",
	"WAKEWORD_2",	
	"LISTEN_START",
	"LISTEN_FINISH",
	"DOCKING_CHARGE",
	"EYE_CONTACT",
	"LOCAL_ROAMING",
	"EXCLAMATION",
	"LOW_BATTERY",
	"ARRIVED_HERE",
	"AWAKE_AND_MOVE",
	"CALL",
	"CALL_PHRASE",
	"DOCKING_CLEAN",
	"TOGGLE",
	"POWER_ON",
	"TASK_END",
	"TASK_FAIL",
	"TASK_COMPLETE",
	"POP_UP_01",
	// "END"
];

export const playEmotionSoundList = [
	"Anticipation",
	"Anxiety",
	"Awake",
	"Awe",
	"Confident",
	"Curiosity",
	"Delight",
	"Dizzy",
	"DoNotKnow",
];