import { Button, CircularProgress } from '@mui/material';
import { styled } from '@mui/material/styles';

// 스타일드 컴포넌트 정의
export const Spinner = styled(CircularProgress)(({ theme }) => ({
  color: theme.palette.secondary.dark,
  position: 'absolute',
  top: '50%',
  left: '50%',
  marginTop: -12,
  marginLeft: -12,
}));

export const LoadingButtonText = styled(Button)(({theme, loading}) => ({  
  color: loading ? theme.palette.grey[500] : theme.palette.secondary.main,
}));

const LoadingButton = styled(Button)(({theme, loading}) => ({  
  backgroundColor: loading ? theme.palette.grey[500] : theme.palette.secondary.main,
  // color: loading ? theme.palette.text.primary : theme.palette.primary.contrastText,
  width: "100px",
  height: "55px",
  '&:hover': {
    backgroundColor: loading ? theme.palette.grey[500] : theme.palette.secondary.dark,
  },
}));

export default LoadingButton;