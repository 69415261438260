import { javascriptGenerator, Order } from "blockly/javascript";

javascriptGenerator.forBlock['c_developer_print'] = function(block, generator) {
	const msg = generator.valueToCode(block, 'TEXT', Order.NONE) || "''";	
	const code = 'alert(' + msg + ');\n';
  return code;
}; 

export function initInterpreterAlert(interpreter, globalObject) {
	// Ensure function name does not conflict with variable names.
	javascriptGenerator.addReservedWords('alert');

	const wrapper = interpreter.createNativeFunction(
		function alert(text) {
			// return window.alert(text);
			return console.log(text);
		}
	);
	interpreter.setProperty(globalObject, 'alert', wrapper);
}

javascriptGenerator.forBlock['c_developer_rootChecker'] = function(block, generator) {
	const code = 'function(){};\n';
	return code;
};

javascriptGenerator.forBlock['c_developer_text_length'] = javascriptGenerator.forBlock['text_length'];
