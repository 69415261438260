import Blockly from 'blockly/core';

// block type naming rule : {toolbox_name}_{block_description}

const programStartJson = {
	"implicitAlign0": "CENTRE",
	"message0": "START : step-by-step %1",
	"args0": [
    {
      "type": "field_checkbox",
      "name": "STEP",
      "checked": "Boolean"
    }
  ],
	"nextStatement": null,
	"colour": "#B01B1B",
	"enableContextMenu": false,	
	"tooltip": "if check step-by-step, you can see the code being executed step by step."
}

Blockly.Blocks['c_program_start'] = {
	init: function() {
		this.jsonInit(programStartJson);
	}
}

const waitUntilJson = {
	"message0": "wait until %1",
	"args0": [
		{
			"type": "input_value",
			"name": "UNTIL",
			"check": "Boolean"
		}
	],
	"inputsInline": true,
	"previousStatement": null,
	"nextStatement": null,
	"style": "logic_blocks",
	"tooltip": "input value must be an async function which can become true at some point."
}

Blockly.Blocks['c_logic_wait_until'] = {
	init: function() {
		this.jsonInit(waitUntilJson);		
	}
}

const waitSecondsJson = {
	"message0": "wait %1 seconds",
	"args0": [
		{
			"type": "field_number",
			"name": "SECONDS",
			"min": 0,
			"max": 600,
			"value": 1
		}
	],
	"previousStatement": null,
	"nextStatement": null,
	"style": "logic_blocks",
}

Blockly.Blocks['c_logic_wait_seconds'] = {
	init: function() {
		this.jsonInit(waitSecondsJson);
	}
}

// Extensions and Mutators