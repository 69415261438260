export const toolboxNavigation = {
  'kind': 'category',
  'name': 'Navigation',
  'categorystyle': 'navigation_category',
  'contents': [
    // {
    //   "kind": "block",
    //   "type": "c_navigation_prepare"
    // },
    // {
    //   "kind": "block",
    //   "type": "c_navigation_createMap_sync"
    // },
    // {
    //   "kind": "block",
    //   "type": "c_navigation_createMap_async"
    // },
    // {
    //   "kind": "block",
    //   "type": "c_navigation_cancelCreateMap"
    // },
    {
      "kind": "block",
      "type": "c_navigation_goHome_sync"
    },
    {
      "kind": "block",
      "type": "c_navigation_goHome_async"
    },
    // {
    //   "kind": "block",
    //   "type": "c_navigation_cancelGoHome"
    // },
    {
      "kind": "block",
      "type": "c_navigation_dockToCharger_sync"
    },
    {
      "kind": "block",
      "type": "c_navigation_dockToCharger_async"
    },
    // {
    //   "kind": "block",
    //   "type": "c_navigation_cancelDockToCharger"
    // },
    {
      "kind": "block",
      "type": "c_navigation_undockFromCharger_sync"
    },
    {
      "kind": "block",
      "type": "c_navigation_undockFromCharger_async"
    },
    // {
    //   "kind": "block",
    //   "type": "c_navigation_cancelUndockFromCharger"
    // },
    // {
    //   "kind": "block",
    //   "type": "c_navigation_exploreNodes_sync"
    // },
    // {
    //   "kind": "block",
    //   "type": "c_navigation_exploreNodes_async"
    // },
    // {
    //   "kind": "block",
    //   "type": "c_navigation_cancelExploreNodes"
    // },
    // {
    //   "kind": "block",
    //   "type": "c_navigation_navigateToSpace_sync"
    // },
    // {
    //   "kind": "block",
    //   "type": "c_navigation_navigateToSpace_async"
    // },
    // {
    //   "kind": "block",
    //   "type": "c_navigation_cancelNavigateToSpace"
    // },
    {
      "kind": "block",
      "type": "c_navigation_spinToFindPerson_sync"
    },
    {
      "kind": "block",
      "type": "c_navigation_spinToFindPerson_async"
    },
    // {
    //   "kind": "block",
    //   "type": "c_navigation_cancelSpinToFindPerson"
    // },
    // {
    //   "kind": "block",
    //   "type": "c_navigation_contactEyes_sync"
    // },
    {
      "kind": "block",
      "type": "c_navigation_contactEyes_async"
    },
    // {
    //   "kind": "block",
    //   "type": "c_navigation_cancelContactEyes"
    // },
    {
      "kind": "block",
      "type": "c_navigation_comeHere_sync"
    },
    {
      "kind": "block",
      "type": "c_navigation_comeHere_async"
    },
    // {
    //   "kind": "block",
    //   "type": "c_navigation_cancelComeHere"
    // },
    // {
    //   "kind": "block",
    //   "type": "c_navigation_followMe_sync"
    // },
    {
      "kind": "block",
      "type": "c_navigation_followMe_async"
    },
    // {
    //   "kind": "block",
    //   "type": "c_navigation_cancelFollowMe"
    // },
    {
      "kind": "block",
      "type": "c_navigation_cancelNaviTask"
    },
  ],
}