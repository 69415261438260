import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";

// mui components
import { 
  Alert,
	Box, 
	Button, 
	ButtonBase, 
	Container, 
	Dialog, 
	DialogActions, 
	DialogContent, 
	DialogContentText, 
	DialogTitle,
	Snackbar,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
	Typography 
} from "@mui/material";
import { styled, useTheme } from '@mui/material/styles';
import { SensorsRounded, SensorsOffRounded } from "@mui/icons-material";

// context
import { robotConnContext } from '../../contexts/ConnectContext';

// images
import startFromEmpty from '../../assets/images/startFromEmpty.jpg';
import startFromSaved from '../../assets/images/startFromSaved.jpg';

import { IS_LOGGING_ON } from "../../config";

const RootStyle = styled('div')(({ theme }) => ({
  position: 'relative',
  backgroundColor: theme.palette.grey[100],
  width: '100%',
  display: 'flex',
  // alignItems: 'center',
  // justifyContent: 'center',
  flexWrap: 'wrap'
}));

const ImageBackdrop = styled('div')(({ theme }) => ({
  position: 'absolute',
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  background: theme.palette.grey[600],
  opacity: 0.5,
  transition: theme.transitions.create('opacity'),
}));

const ImageIconButton = styled(ButtonBase)(({ theme }) => ({
  position: 'relative',
  display: 'block',
  padding: 0,
  borderRadius: 0,
	// width: '25vh',
  height: '30vh',	
  '&:hover': {
    zIndex: 1,
  },
  '&:hover .imageBackdrop': {
    opacity: 0.15,
  },
  '&:hover .imageMarked': {
    opacity: 0,
  },
  '&:hover .imageTitle': {
    border: '4px solid currentColor',
  },
  '& .imageTitle': {
    position: 'relative',
    padding: `${theme.spacing(2)} ${theme.spacing(2)} 14px`,
  },
  '& .imageMarked': {
    height: 3,
    width: 18,
    background: theme.palette.common.white,
    position: 'absolute',
    bottom: -2,
    left: 'calc(50% - 9px)',
    transition: theme.transitions.create('opacity'),
  },
}));

const vertical = 'bottom', horizontal = 'center';

export default function CreateOrEdit() {

  const navigate = useNavigate();
  const theme = useTheme();
  
  const { isReadyContext } = useContext(robotConnContext);

	const [savedWSList, setSavedWSList] = useState(null);
	const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
	const [workspaceToDelete, setWorkspaceToDelete] = useState(null);
	const [openDeleteWSSuccess, setOpenDeleteWSSuccess] = useState(false);	

  const [toggleValue, setToggleValue]= useState('Edit');

	useEffect(() => {
		// if(IS_LOGGING_ON) console.log('local storage:', Object.keys(window.localStorage));
		const storedObject = window.localStorage;
		const wsList = Object.keys(storedObject);
		if(IS_LOGGING_ON) console.log('workspace list:', wsList);
		if (wsList){
			setSavedWSList(wsList);
		}		
  }, [])

  const handleToggle = (e, newValue) => {
		setToggleValue(newValue);
	}

	const handleClick = (ws) => {
    if(toggleValue === 'Edit'){
      navigate('/Workspace',  { state: { savedWorkspace: ws } })
    } else {
      console.info('delete saved workspace:', ws);		
      setWorkspaceToDelete(ws);
		  setOpenDeleteDialog(true);
    }   
  };

  const handleCloseDelete = () => {
    setOpenDeleteDialog(false);
		setWorkspaceToDelete(null);
  }

	const handleCloseDeleteWSSuccess = () => {
		setOpenDeleteWSSuccess(false);
	}

  return (
		<>		        
      <RootStyle>
        <Container
          disableGutters          
          maxWidth={false}
          sx={{            
            mt: 5,
            ml: 10,
            display: 'flex',            
            justifyContent: 'flex-start',
          }}
        >
          <Stack direction="row" spacing={2}>
          {isReadyContext ?
            <SensorsRounded color="secondary" sx={{ fontSize: 60 }} /> 
            : <SensorsOffRounded color="error" sx={{ fontSize: 60 }}/>
          }
            <Typography 
              variant="h4" 
              // component="div"              
              color={isReadyContext ? theme.palette.secondary.main : theme.palette.error.main }
              sx={{ alignContent: 'center' }}
            >{isReadyContext ? 'Ready to code' : 'Connection setup is required'}</Typography>            
          </Stack>
        </Container>
        <Container           
          disableGutters
          maxWidth={false}
          sx={{            
            mt: 3,
            display: 'flex',                        
            justifyContent: 'center',
            alignItems:"center",
          }}
        >
          <Typography variant="h2" align="center" sx={{ px: 10, color: 'text.secondary' }}>
            Create or Edit block code
          </Typography>
        </Container>   
        <Container           
          disableGutters
          maxWidth={false}
          sx={{         
            ml: 8,   
            mt: 3,
            display: 'flex',                        
            justifyContent: 'flex-start',         
          }}
        >
          <ToggleButtonGroup
            color="secondary"
            value={toggleValue}
            size="large"
            exclusive
            onChange={handleToggle}
          >
            <ToggleButton value="Edit">Edit</ToggleButton>
            <ToggleButton value="Delete">Delete</ToggleButton>
          </ToggleButtonGroup>
        </Container>
        <Box 
					sx={{  
            ml: 5,           
            mt: 2,
            mb: 5,
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'flex-start',            
        }}>
					<ImageIconButton	
            key="new"        
            style={{
              width: '30vh',
            }}   
            disabled={toggleValue === 'Delete'}
						onClick={() => navigate('/Workspace')}
					>
						<Box
							sx={{
								position: 'absolute',
								left: 0,
								right: 0,
								top: 0,
								bottom: 0,
                width: '30vh',
                height: '30vh',                
								backgroundSize: 'cover',
								backgroundPosition: 'center 40%',
								backgroundImage: `url(${startFromEmpty})`,		
							}}
						/>									
            <ImageBackdrop className="imageBackdrop" />
            <Box
              sx={{
                position: 'absolute',
                left: 0,
                right: 0,
                top: 0,
                bottom: 0,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                color: 'common.white',
              }}
            >
              <Typography
                variant="subtitle1"
                color="inherit"
                className="imageTitle"
              >
                + Create New Block
                <div className="imageMarked" />
              </Typography>
            </Box>
					</ImageIconButton>		
				{savedWSList &&
					savedWSList.map((ws) => (	
						<ImageIconButton
              key={ws} 
              style={{
                width: '30vh',
              }}
							onClick={() => handleClick(ws)}							
						>				                          
							<Box
								sx={{
									position: 'absolute',
									left: 0,
									right: 0,
									top: 0,
									bottom: 0,
                  width: '30vh',
                  height: '30vh',       
									backgroundSize: 'cover',
									backgroundPosition: 'center 100%',
									backgroundImage: `url(${startFromSaved})`,
								}}
							/>
              <ImageBackdrop className="imageBackdrop" />
              <Box
                sx={{
                  position: 'absolute',
                  left: 0,
                  right: 0,
                  top: 0,
                  bottom: 0,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  color: 'common.white',
                }}
              >                
                <Typography
                  variant="subtitle1"
                  color="inherit"
                  noWrap
                  className="imageTitle"
                >
                  {`${toggleValue} ${ws}`}
                  <div className="imageMarked" />
                </Typography>
              </Box>
						</ImageIconButton>		          
				))}
        </Box> 
      </RootStyle>
			<Dialog
        open={openDeleteDialog}
        onClose={handleCloseDelete}
        PaperProps={{
          component: 'form',
          onSubmit: (event) => {
            event.preventDefault();
            if(IS_LOGGING_ON) console.log('delete workspace:', workspaceToDelete);		
						window.localStorage.removeItem(workspaceToDelete);
						setOpenDeleteWSSuccess(true);

						const storedObject = window.localStorage;				
						const wsList = Object.keys(storedObject);
						if(IS_LOGGING_ON) console.log('new workspace list:', wsList);
						wsList ? setSavedWSList(wsList) : setSavedWSList(null);

            handleCloseDelete();
          },
        }}
      >
        <DialogTitle>Delete Saved Workspace</DialogTitle>
        <DialogContent>
          <DialogContentText>
						{`Are you sure you want to delete workspace ${workspaceToDelete}?`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color='secondary' onClick={handleCloseDelete}>Cancel</Button>
          <Button type="submit" color='secondary'>Delete</Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
				open={openDeleteWSSuccess}
				autoHideDuration={6000}
				onClose={handleCloseDeleteWSSuccess}
      >
        <Alert
          onClose={handleCloseDeleteWSSuccess}
          severity="info"
          variant="filled"
          sx={{ width: '100%', fontFamily: 'Roboto', fontWeight: 200 }}
        >
          Workspace Deleted Successfully
        </Alert>
      </Snackbar>
		</>
  );
}