// @mui
import { Box, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
// components
import Logo from '../components/Logo';

// ----------------------------------------------------------------------

export default function MainFooter() {
  const theme = useTheme();

  return (
    <Box
      sx={{
        height: 70,
        textAlign: 'start',
        position: 'relative',
        bgcolor: theme.palette.background.neutral
      }}
    >
      <Stack
        direction='row'
        sx={{
          position: 'relative',
          left: 30,          
        }}
      >
        <Logo/>

        <Typography variant="caption" sx={{ ml: 2, mt: 4 }}>
          Copyright © 2024 LG Electronics. All rights reserved.
        </Typography>
      </Stack>
    </Box>
  );
}
