export const facialExpressionList = [
	// eDISPLAY_NONE = 0,
	// "Look_Around_L_A",
	// "Look_Around_L_B",
	// "Look_Around_R_A",
	// "Look_Around_R_B",
	// "Look_Around_T_A",
	// "Look_Around_T_B",
	// "Look_Around_B_A",
	// "Look_Around_B_B",
	"Awe",
	"Exciting",
	"Surprise",
	"Anticipation",
	"Confident",
	"Love",
	"Delight",
	"Joy",
	"Curiosity",
	"Sleepy",
	"Dozz_Off",	
	"Relieved",
	"Satisfied",
	"Touched_A",
	"Touched_B",
	"Agree",
	"Shyness_A",
	"Shyness_B",
	"Proud",
	"Interested",
	"Playful",
	"Tremble",
	"Thrilled",
	"Thinking_A",
	"Thinking_B",
	"Dizzy",
	"Sadness",
	"Shame",
	"Anxiety",
	"Fear",
	"Ashamed",
	"Shocked",
	"Bored",
	"Tired",
	"Confused",
	"Embarrassed",
	"Jealous",
	"Suspicious",
	"Mad",
	"Unsatisfiyed",
	"Annoyed"
];

/*
eDISPLAY_NONE = 0,
// Dialogue
eDISPLAY_D1_Look_Around_L_A,
eDISPLAY_D1_Look_Around_L_B,
eDISPLAY_D1_Look_Around_R_A,
eDISPLAY_D1_Look_Around_R_B,
eDISPLAY_D1_Look_Around_T_A,
eDISPLAY_D1_Look_Around_T_B,
eDISPLAY_D1_Look_Around_B_A,
eDISPLAY_D1_Look_Around_B_B,
// Emotion
eDISPLAY_E1_Awe,
eDISPLAY_E2_Exciting,    // 10
eDISPLAY_E3_Surprise,
eDISPLAY_E4_Anticipation,
eDISPLAY_E5_Confident,
eDISPLAY_E6_Love,
eDISPLAY_E7_Delight,
eDISPLAY_E8_Joy,
eDISPLAY_E9_Curiosity,
eDISPLAY_E10_Sleepy,
eDISPLAY_E11_Dozz_Off,
eDISPLAY_E12_Relieved,    // 20
eDISPLAY_E13_Satisfied,
eDISPLAY_E14_Touched_A,
eDISPLAY_E14_Touched_B,
eDISPLAY_E15_Agree,
eDISPLAY_E16_Shyness_A,
eDISPLAY_E16_Shyness_B,
eDISPLAY_E17_Proud,
eDISPLAY_E18_Interested,
eDISPLAY_E19_Playful,
eDISPLAY_E20_Tremble,      // 30
eDISPLAY_E21_Thrilled,
eDISPLAY_E22_Thinking_A,
eDISPLAY_E22_Thinking_B,

eDISPLAY_E51_Dizzy,
eDISPLAY_E52_Sadness,
eDISPLAY_E53_Shame,
eDISPLAY_E54_Anxiety,
eDISPLAY_E55_Fear,
eDISPLAY_E56_Ashamed,
eDISPLAY_E57_Shocked,      // 40
eDISPLAY_E58_Bored,
eDISPLAY_E59_Tired,
eDISPLAY_E60_Confused,
eDISPLAY_E61_Embarrassed,
eDISPLAY_E62_Jealous,
eDISPLAY_E63_Suspicious,
eDISPLAY_E64_Mad,
eDISPLAY_E65_Unsatisfiyed,
eDISPLAY_E66_Annoyed,
// Function
eDISPLAY_F0_Conversation_Listening,     // 50
eDISPLAY_F0_Conversation_Analyzing,
eDISPLAY_F0_Conversation_Recognition,
eDISPLAY_F1_1_Camera_Icon_A,
eDISPLAY_F1_1_Camera_Icon_B,
eDISPLAY_F1_2_Camera_Eyes,
eDISPLAY_F2_1_Cleaning_Icon_A,
eDISPLAY_F2_1_Cleaning_Icon_B,
eDISPLAY_F2_2_Cleaning_Eyes_A,
eDISPLAY_F2_2_Cleaning_Eyes_B,
eDISPLAY_F3_1_Voice_Mail_Icon_A,       // 60
eDISPLAY_F3_1_Voice_Mail_Icon_B,
eDISPLAY_F4_Low_Battery_Icon,
eDISPLAY_F5_Checking_Icon,
eDISPLAY_F6_Carrying,
eDISPLAY_F8_Clock,
eDISPLAY_F9_Power_Off,
eDISPLAY_F9_Power_On,
eDISPLAY_F10_Music_eyes,
eDISPLAY_F11_1_Sun,
eDISPLAY_F11_2_Partly_Cloudy,      // 70
eDISPLAY_F11_3_Cloudy,
eDISPLAY_F11_4_Rain,
eDISPLAY_F11_5_Snow,
eDISPLAY_F11_6_Cloudy_Rain_Snow,
eDISPLAY_F11_8_Foggy,
eDISPLAY_F11_10_Thunder,
eDISPLAY_F11_14_Cloudy_Snow,
eDISPLAY_F11_15_Cloudy_Snow_Mixed,
eDISPLAY_F11_18_Rain_To_Clear,
eDISPLAY_F11_19_Snow_To_Clear,       // 80
eDISPLAY_F11_20_Snow_Rain_To_Clear,
eDISPLAY_F12_Drawing_Icon_A,
eDISPLAY_F12_Drawing_Icon_B,
eDISPLAY_F13_Conversation_Failed_Icon,
// Standby
eDISPLAY_S1_Neutral_A,     // 85
eDISPLAY_S1_Neutral_B,     // 86
eDISPLAY_S1_Neutral_C,     // 87
eDISPLAY_S2_Charging,
eDISPLAY_S2_DEMO_Charging,

eDISPLAY_V1_1_Christmas,         // 90
eDISPLAY_V1_2_Christmas,
eDISPLAY_V2_Halloween,

eDISPLAY_Neutral_Random,

eDISPLAY_Q9_Face_motion_Sunny,
eDISPLAY_Q9_Face_motion_Rainy,
eDISPLAY_Q9_Face_motion_Info_Sunny,
eDISPLAY_Q9_Face_motion_Info_Rainy,
eDISPLAY_Q9_Face_motion_voice_delivery_1,
eDISPLAY_Q9_Face_motion_voice_delivery_2,

eDISPLAY_Hello_Idle,

eDISPLAY_DEMO_PUSH_1,
eDISPLAY_DEMO_PUSH_2,
eDISPLAY_DEMO_PUSH_3,

eDISPLAY_END,
*/