import { IS_LOGGING_ON } from "./config";

export function Logger(log) {                 
  if(!IS_LOGGING_ON) return;
  console.log(log);
  // const now = new Date();
  // const utc = now.getTime();
  // const koreaTimeDiff = 9 * 60 * 60 * 1000;
  // const korNow = new Date(utc+koreaTimeDiff);
  // const date = korNow.toISOString();
  // const logMsg = `[${date}]${log}`;
  // console.log(logMsg);
};