import Blockly from 'blockly/core';
import { Msg } from 'blockly/core';

// block type naming rule : {toolbox_name}_{block_description}

const repeatInfinitelyJson = {
	"message0": "repeat infinitely %1 do %2",
	"args0": [
		{
			"type": "input_dummy"
		},
		{
			"type": "input_statement",
			"name": "DO"
		}
	],
	"previousStatement": null,
	"nextStatement": null,
	"style": "loop_blocks",
}

Blockly.Blocks['c_loop_repeat_infinitely'] = {
	init: function() {
		this.jsonInit(repeatInfinitelyJson);
	}
}

const repeatUntilJson = {
	"message0": "repeat until %1 %2 do %3",
	"args0": [
		{
			"type": "input_value",
			"name": "UNTIL",
			"check": "Boolean"
		}, 
		{
			"type": "input_dummy"
		},
		{
			"type": "input_statement",
			"name": "DO"
		},
	],
	"inputsInline": true,
	"previousStatement": null,
	"nextStatement": null,
	"style": "loop_blocks",
}

Blockly.Blocks['c_loop_repeat_until'] = {
	init: function() {
		this.jsonInit(repeatUntilJson);
	}
}

const repeatWhileJson = {
	"message0": "repeat while %1 %2 do %3",
	"args0": [
		{
			"type": "input_value",
			"name": "WHILE",
			"check": "Boolean"
		},
		{
			"type": "input_dummy"
		},
		{
			"type": "input_statement",
			"name": "DO"
		}
	],
	"inputsInline": true,
	"previousStatement": null,
	"nextStatement": null,
	"style": "loop_blocks",
}

Blockly.Blocks['c_loop_repeat_while'] = {
	init: function() {
		this.jsonInit(repeatWhileJson);
	}
}

const loopTypes = new Set([
	'c_loop_repeat_infinitely',
	'c_loop_repeat_until',
	'c_loop_repeat_while',
	'controls_repeat_ext'
])

const continueJson = {
	"message0": "continue",
	"inputsInline": false,
	"previousStatement": null,
	"nextStatement": null,
	"style": "loop_blocks",
}

Blockly.Blocks['c_loop_continue'] = {
	init: function() {
		this.jsonInit(continueJson);
	},

	getSurroundLoop: function() {
		// console.log(`check if this ${this.type} block is in loop`);
		let block = this.getSurroundParent();
		if (block){
			do {
				if (loopTypes.has(block.type)) {
					// console.log('block type:', block.type);
					return block;
				}
				block = block.getSurroundParent();
			} while(block);
		}		
		return null;
	},

	onchange: function(e) {		
		if (this.workspace.isDragging()) return;
		if (e.type !== Blockly.Events.BLOCK_MOVE) return;
		if (!this.getSurroundLoop()) {
			// this.outputConnection.disconnect();
			this.setEnabled(false);
			this.setWarningText(Msg['CONTROLS_FLOW_STATEMENTS_WARNING']);
		} else {
			this.setEnabled(true);
			this.setWarningText('');
		}		
	}
}

const breakJson = {	
	"message0": "break",
	"inputsInline": false,
	"previousStatement": null,
	"nextStatement": null,
	"style": "loop_blocks",
}

Blockly.Blocks['c_loop_break'] = {
	init: function() {
		this.jsonInit(breakJson);
	},

	getSurroundLoop: function() {
		// console.log(`check if this ${this.type} block is in loop`);
		let block = this.getSurroundParent();
		if (block){
			do {
				if (loopTypes.has(block.type)) {
					// console.log('block type:', block.type);
					return block;
				}
				block = block.getSurroundParent();
			} while(block);
		}		
		return null;
	},

	onchange: function(e) {		
		if (this.workspace.isDragging()) return;
		if (e.type !== Blockly.Events.BLOCK_MOVE) return;
		if (!this.getSurroundLoop()) {
			// this.outputConnection.disconnect();
			this.setEnabled(false);
			this.setWarningText(Msg['CONTROLS_FLOW_STATEMENTS_WARNING']);
		} else {
			this.setEnabled(true);
			this.setWarningText('');
		}		
	}
}

// Extensions and Mutators

