import Blockly from 'blockly/core';

export const themeForCustomBasic = Blockly.Theme.defineTheme('custom_basic', {
  'base': Blockly.Themes.Zelos,  
  // 'componentStyles': {
  //   'workspaceBackgroundColour': '#1e1e1e',
  //   'toolboxBackgroundColour': 'blackBackground',
  //   'toolboxForegroundColour': '#fff',
  //   'flyoutBackgroundColour': '#252526',
  //   'flyoutForegroundColour': '#ccc',
  //   'flyoutOpacity': 1,
  //   'scrollbarColour': '#797979',
  //   'insertionMarkerColour': '#fff',
  //   'insertionMarkerOpacity': 0.3,
  //   'scrollbarOpacity': 0.4,
  //   'cursorColour': '#d0d0d0',
  //   'blackBackground': '#333',
  // },
	'categoryStyles' : {
    'interaction_category': {
			'colour': '#FF4747'
		},
   'motion_category': {
			'colour': '#EC6AA7',
		},    
    'movement_category': {
			'colour': '#CE6AEC'
		},
    'sensing_category': {
			'colour': '#B716E8'
		},    
    'navigation_category': {
			'colour': '#4DCCF3'
		},    		
		'developer_category': {
			'colour': '#FFC625',
		},    
	},
  'blockStyles': {
    'interaction_blocks': {
			'colourPrimary': '#FF4747'
    },
    'motion_blocks': {
			'colourPrimary': '#EC6AA7'
    },    
		'movement_blocks': {
			'colourPrimary': '#CE6AEC'
    },  
    'sensing_blocks': {
			'colourPrimary': '#B716E8'
    },  
    'navigation_blocks': {
			'colourPrimary': '#4DCCF3'
    },
		'developer_blocks': {
			'colourPrimary': '#FFC625'
    },  
    'completed_blocks': {
			'colourPrimary': '#7A7777',
      'colourTertiary':'#DC2222'
    },  
	},
	'fontStyle': {

	},
});