const backpackOptions = {
  allowEmptyBackpackOpen: true,
  useFilledBackpackImage: true,
  skipSerializerRegistration: false,
  contextMenu: {
    emptyBackpack: true,
    removeFromBackpack: true,
    copyToBackpack: true,
    copyAllToBackpack: false,
    pasteAllToBackpack: false,
    disablePreconditionChecks: false,
  },
};

export default backpackOptions;